import React, { useEffect, useState, useRef } from "react";
import "./dashboard.css";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import * as XLSX from "xlsx";
import { FilterBox } from "./Dashboard";
import BarGraph from "./Barchart";
import Select, {components} from "react-select";
import html2pdf  from "html2pdf.js";
import axiosInstance from '../api/axios';


const MyOut = ({ accounts, serverurl, blist }) => {
  const [tdata, setTdata] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [buy, setBuy] = useState("");
  const [showTable, setShowTable] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [ldays, setLdays] = useState("");
  const [toDate, setToDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [partyData, setPartyData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [activeTab, setActiveTab] = useState("bill");
  const [broker, setBroker] = useState("");
  const [showFbtn, setShowFbtn] = useState(true);
  const [sdesign, setSdesign] = useState("")
  const selectref = useRef(null)
  const [sortConfigP, setSortConfigP] = useState({
    key: "buyer",
    direction: "asc",
  });
  const buyerOptions = accounts.map((b) => ({ value: b, label: b }));
  const brokerOptions = blist.map((b) => ({ value: b, label: b }));

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const sortTableP = (key) => {
    let direction = "asc";
    if (sortConfigP.key === key && sortConfigP.direction === "asc") {
      direction = "desc";
    }
    setSortConfigP({ key, direction });
  };

  const sortedDataP = [...partyData].sort((a, b) => {
    if (sortConfigP.key === "buyer") {
      if (sortConfigP.direction === "asc") {
        return a[sortConfigP.key].localeCompare(b[sortConfigP.key]);
      } else {
        return b[sortConfigP.key].localeCompare(a[sortConfigP.key]);
      }
    } else {
      if (sortConfigP.direction === "asc") {
        return a[sortConfigP.key] - b[sortConfigP.key];
      } else {
        return b[sortConfigP.key] - a[sortConfigP.key];
      }
    }
  });

  const [sortConfig, setSortConfig] = useState({
    key: "invdate",
    direction: "asc",
  });

  const sortTable = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = [...filteredData].sort((a, b) => {
    const keyA = a[sortConfig.key];
    const keyB = b[sortConfig.key];
    // Custom comparison for date column
    if (sortConfig.key === "date" || sortConfig.key == 'invdate') {
      const dateA = new Date(keyA.split("-").reverse().join("-"));
      const dateB = new Date(keyB.split("-").reverse().join("-"));
      return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
    }
    if (typeof keyA === "number" && typeof keyB === "number") {
      return sortConfig.direction === "asc" ? keyA - keyB : keyB - keyA;
    }
    // Default comparison for other columns
    return sortConfig.direction === "asc"
      ? keyA.localeCompare(keyB)
      : keyB.localeCompare(keyA);
  });

  const fetchInitialData = async () => {
    try {
      setTableLoading(true);
      setIsLoading2(true);
      const response = await axiosInstance.post(`/api/data/outS`, {        
        buyerNames: accounts.map(item => item.trim()),

      });
      setTdata(response.data.tdata);
      console.log(response.data)
      setFilteredData(response.data.tdata);
      const processedData = processData(response.data.tdata);
      setPartyData(processedData);
      const monthlyProData = transformDataToMonthlyTotals(response.data.tdata);
      setMonthlyData(monthlyProData);
      setTableLoading(false);
      setIsLoading2(false);
      setShowTable(true);
      setTimeout(() => {
        const footerElement = document.getElementById("footer");
        const tableContainerElement =
          document.getElementById("table-container");

        if (footerElement && tableContainerElement) {
          const footerOffset = footerElement.offsetTop;
          const tableContainerOffset = tableContainerElement.offsetTop;
          const calculatedHeight = footerOffset - tableContainerOffset - 2;
          tableContainerElement.style.height = `${calculatedHeight}px`;
        }
      }, 250);
    } catch (error) {
      console.log(error);
    }
  };

  const contentRef = useRef(null)

  // const handleDownloadPDF = () => {
  //   if (contentRef.current) {
  //     const printableContent = contentRef.current;
  //     const width = printableContent.scrollWidth; // Get the width of the table
  //     const additionalSpace = window.innerWidth < 900 ? 200 : 0; // Check device width
  //     console.log(additionalSpace)
  
  //     html2pdf()
  //       .set({
  //         margin: 1,
  //         filename: `${activeTab}-OS.pdf`,
  //         image: { type: 'jpeg', quality: 0.98 },
  //         html2canvas: { scale: 2, width: width + additionalSpace }, // Set width + additional space
  //         jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }
  //       })
  //       .from(printableContent)
  //       .save();
  //   }
  // };
  
  const handleDownloadPDF = async () => {
    const data = {
      companyName: 'NEELKAMAL FURNISHING',
      heading: 'Sales Outstanding Report',
      rcolor: '0B2B43',
      columns: ['Inv No', 'Inv Dt.', 'Buyer', 'Inv Amt', 'Part Recd', 'O/S Amt', 'Days'],
      groupedData: [...filteredData].reduce((acc, invoice) => {
        const { buyer } = invoice;
        if (!acc[buyer]) {
          acc[buyer] = [];
        }
        acc[buyer].push(invoice);
        return acc;
      }, {}),
      cols: ["invno", "invdate", "buyer", "netamt", "partpmnt", "balamt", "latedays"],
  };
  
    try {
      const response = await axiosInstance.post("api/data/gensendpdfos", data, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "outstanding_report.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };









  useEffect(() => {
    setTimeout(() => {
      const footerElement = document.getElementById("footer");
      const tableContainerElement = document.getElementById("table-container");

      if (footerElement && tableContainerElement) {
        const footerOffset = footerElement.offsetTop;
        const tableContainerOffset = tableContainerElement.offsetTop;
        const calculatedHeight = footerOffset - tableContainerOffset - 2;
        tableContainerElement.style.height = `${calculatedHeight}px`;
      }
    }, 250);
  }, [activeTab, showFilters]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.keyCode === 13) {
        const button = document.getElementById("search");
        if (button) {
          button.click();
        }
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const fetchFilteredData = async () => {
    setIsLoading2(true);
    setShowFilters(false);
    setShowFbtn(true);
    try {
      const response = await axiosInstance.post(
        `/api/data/fos/${buy == "" ? "x" : buy}/${
          ldays == "" ? "x" : ldays
        }/${toDate == "" ? "x" : toDate}/${broker == "" ? "x" : broker}`,
        {
          
            buyerNames: accounts.map(item => item.trim()),
          
        }
      );
      setTdata(response.data.tdata);
      setFilteredData(response.data.tdata);
      const processedData = processData(response.data.tdata);
      setPartyData(processedData);
      const monthlyProData = transformDataToMonthlyTotals(response.data.tdata);
      setMonthlyData(monthlyProData);
      setShowTable(true);
      setIsLoading2(false);
      setTimeout(() => {
        const footerElement = document.getElementById("footer");
        const tableContainerElement =
          document.getElementById("table-container");

        if (footerElement && tableContainerElement) {
          const footerOffset = footerElement.offsetTop;
          const tableContainerOffset = tableContainerElement.offsetTop;
          const calculatedHeight = footerOffset - tableContainerOffset - 2;
          tableContainerElement.style.height = `${calculatedHeight}px`;
        }
      }, 250);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=> {
    const fetchFilteredData1 = async () => {
      setIsLoading2(true);
      setShowFilters(false);
      setShowFbtn(true);
      try {
        const response = await axiosInstance.post(
          `/api/data/fos/${sdesign == "" ? (buy == "" ? "x" : buy) : sdesign}/${
            ldays == "" ? "x" : ldays
          }/${toDate == "" ? "x" : toDate}/${broker == "" ? "x" : broker}`,
          {
            
              buyerNames: accounts.map(item => item.trim()),
            
          }
        );
        setTdata(response.data.tdata);
        setFilteredData(response.data.tdata);
        const processedData = processData(response.data.tdata);
        setPartyData(processedData);
        const monthlyProData = transformDataToMonthlyTotals(response.data.tdata);
        setMonthlyData(monthlyProData);
        setShowTable(true);
        setIsLoading2(false);
        setTimeout(() => {
          const footerElement = document.getElementById("footer");
          const tableContainerElement =
            document.getElementById("table-container");
  
          if (footerElement && tableContainerElement) {
            const footerOffset = footerElement.offsetTop;
            const tableContainerOffset = tableContainerElement.offsetTop;
            const calculatedHeight = footerOffset - tableContainerOffset - 2;
            tableContainerElement.style.height = `${calculatedHeight}px`;
          }
        }, 250);
      } catch (error) {
        console.log(error);
      }
    };
    fetchFilteredData1()
  }, [sdesign])

  const download = async (no) => {
    try {
      const response = await axiosInstance.get(`/api/data/download-pdf/${no}`, {
        responseType: "blob",
      });

      

      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${no}-SB.pdf`);

      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error("Error downloading PDF:", error);
      // Handle error
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      const filtered = tdata.filter(
        (item) => item.netamt === 0 && item.partpmnt > 0
      );
      setFilteredData(filtered);
    } else {
      // Show original data when checkbox is unchecked
      setFilteredData(tdata);
    }
  };

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.table_to_sheet(
      document.getElementById("delTable")
    );
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `My ${activeTab} O/S.xlsx`);
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const processData = (data) => {
    // Process data similar to your SQL query
    const processedData = {};

    data.forEach((item) => {
      const { buyer, netamt, partpmnt, balamt, latedays } = item;
      if (!processedData[buyer]) {
        processedData[buyer] = {
          buyer,
          netamt: 0,
          partpmnt: 0,
          osamt: 0,
          amt1: 0,
          amt2: 0,
          amt3: 0,
          amt4: 0,
        };
      }
      processedData[buyer].netamt += parseFloat(netamt);
      processedData[buyer].partpmnt += parseFloat(partpmnt);
      processedData[buyer].osamt += parseFloat(balamt);
      if (latedays >= 0 && latedays <= 30) {
        processedData[buyer].amt1 += parseFloat(balamt);
      } else if (latedays >= 31 && latedays <= 60) {
        processedData[buyer].amt2 += parseFloat(balamt);
      } else if (latedays >= 61 && latedays <= 90) {
        processedData[buyer].amt3 += parseFloat(balamt);
      } else if (latedays >= 91) {
        processedData[buyer].amt4 += parseFloat(balamt);
      }
    });

    return Object.values(processedData);
  };

  const CustomControl = (props) => (
    <components.Control {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        height={"20px"}
        width={"20px"}
      >
        <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
      </svg>
      {props.children}
    </components.Control>
  );

  const transformDataToMonthlyTotals = (originalData) => {
    const monthlyTotals = {};

    originalData.forEach((item) => {
      const { invdate, balamt } = item;
      const [day, month, year] = invdate.split("-");

      const key = `${month}-${year}`;
      if (!monthlyTotals[key]) {
        monthlyTotals[key] = {
          month: getMonthName(parseInt(month, 10)),
          year,
          total_balance: 0,
        };
      }

      monthlyTotals[key].total_balance += parseFloat(balamt);
    });

    console.log(monthlyTotals);

    // Convert object to array and sort by year and month
    const sortedMonthlyTotals = Object.values(monthlyTotals).sort((a, b) => {
      const monthsMap = {
        Jan: 1,
        Feb: 2,
        Mar: 3,
        Apr: 4,
        May: 5,
        Jun: 6,
        Jul: 7,
        Aug: 8,
        Sep: 9,
        Oct: 10,
        Nov: 11,
        Dec: 12,
      };

      // Compare years first
      if (a.year !== b.year) {
        return a.year - b.year;
      }

      console.log(a.month, b.month);
      // If years are equal, compare months using monthsMap
      return monthsMap[a.month] - monthsMap[b.month];
    });

    // Calculate cumulative balance for each month after sorting
    let cumulativeBalance = 0;
    sortedMonthlyTotals.forEach((item) => {
      item.cumulative_balance = cumulativeBalance + parseFloat(item.total_balance);
      cumulativeBalance = item.cumulative_balance;
    });

    return sortedMonthlyTotals;
  };

  const getMonthName = (monthNumber) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[monthNumber - 1];
  };

  return (
    <div className="container">
      <>
        {!tableLoading ? (
          <>
            {showFbtn && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center"
                }}
              >
              {!(buy || broker || ldays || toDate) && (
                <div
                  className="filter-item cfi"
                  style={{ marginBottom: "0px", flex: "1" }}
                >
                  <Select
                    ref={selectref}
                    value={sdesign ? { value: sdesign, label: sdesign } : null}
                    options={buyerOptions.sort((a, b) =>
                      a.label.toString().localeCompare(b.label.toString())
                    )}
                    placeholder={"Search Buyer"}
                    isClearable={true}
                    components={{ Control: CustomControl }}
                    onChange={(option) => {
                      setSdesign(option == null ? "" : option.label);
                      if (option == null && selectref.current) {
                        selectref.current.blur();
                      }
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "100%",
                        paddingInlineStart: "10px",
                        borderRadius: "25px",
                        backgroundColor: "lightgrey",
                        textAlign: "left",
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        maxHeight: "200px",
                        textAlign: "left",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 6,
                      }),
                      container: (provided) => ({
                        ...provided,
                        width: "90%",
                      }),
                    }}
                  />
                </div>
              )}
                <button
                  className="filter-btn"
                  onClick={() => {
                    if(buy || broker || ldays || toDate){
                      setToDate("");
                      setLdays("");
                      setBuy("");
                      setBroker("")
                      fetchInitialData();
                    }else {
                      setShowFilters(!showFilters);
                      setShowFbtn(false);
                    }                    
                  }}
                >
                  {buy || broker || ldays || toDate ? "Clear" : "More"} Filters
                </button>
                <p style={{ marginLeft: "5px", color: 'red' , fontStyle: 'italic'}}>
                    {
                    buy ||
                    broker ||
                    ldays ||
                    toDate 
                     ? (
                      <>
                        Showing Results for:{" "}                      
                        {buy && `"${buy}"`}{" "}
                        {ldays && `"${ldays}"`}{" "}
                        {toDate && `"${toDate}"`}{" "}
                        {broker && `"${broker}"`}{" "}           
                      </>
                    ) : (
                      <></>
                    )}
                  </p>
              </div>
            )}
            {showFilters && (
              <div className="filter-boxes fbos">
                <div className="filter-item">
                  <p
                    onClick={() => {
                      setShowFbtn(true);
                      setShowFilters(false);
                    }}
                    style={{
                      cursor: "pointer",
                      border: "2px solid #0B2B43",
                      borderRadius: "50%",
                      padding: "5px 10px 5px 10px",
                      color: "white",
                      backgroundColor: "#0B2B43",
                    }}
                  >
                    X
                  </p>
                </div>
                <div className="filter-item osb">
                  <Select
                    value={buy ? { value: buy, label: buy } : null}
                    options={buyerOptions}
                    placeholder={"Buyer"}
                    isClearable={true}
                    onChange={(option) => {
                      setBuy(option == null ? "" : option.label);
                      setShowTable(false);
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "200px", // Adjust width as needed
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        maxHeight: "200px", // Adjust height as needed
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 6,
                      }),
                    }}
                  />
                </div>
                <div className="filter-item osb">
                  <Select
                    value={broker ? { value: broker, label: broker } : null}
                    options={brokerOptions}
                    placeholder={"Broker"}
                    isClearable={true}
                    onChange={(option) => {
                      setBroker(option == null ? "" : option.label);
                      setShowTable(false);
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "200px", // Adjust width as needed
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        maxHeight: "200px", // Adjust height as needed
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 6,
                      }),
                    }}
                  />
                </div>
                <div className="filter-item">
                  <input
                    className="filterip osbdate"
                    type="text"
                    placeholder="Till Date"
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                    value={toDate}
                    onChange={(e) => {
                      setToDate(e.target.value);
                      setShowTable(false);
                    }}
                    style={{
                      width: "150px",
                      padding: "8px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      height: "33.45px",
                    }}
                  />
                </div>
                <div className="filter-item">
                  <input
                    className="filterip "
                    type="text"
                    placeholder="Late Days"
                    value={ldays}
                    onFocus={(event) => event.target.select()}
                    onChange={(e) => {
                      setLdays(e.target.value);
                      setShowTable(false);
                    }}
                    style={{
                      width: "100px",
                      padding: "8px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                  />
                </div>
                <div className="filter-item ">
                  <input
                    style={{ marginRight: "2px" }}
                    type="checkbox"
                    name="inacc"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="inacc"> ON A/C</label>
                </div>
                <div className="filter-item ">
                  <button
                    id="search"
                    onClick={() => {
                      setSdesign("")
                      fetchFilteredData();
                    }}
                    style={{
                      width: "80px",
                      margin: 0,
                      fontSize: "12px",
                      padding: "10px",
                      height: "auto",
                    }}
                  >
                    Search
                  </button>
                  <button
                    onClick={() => {
                      setToDate("");
                      setLdays("");
                      setBuy("");
                      setBroker("")
                      fetchInitialData();
                    }}
                    style={{
                      width: "80px",
                      margin: 0,
                      fontSize: "12px",
                      padding: "10px",
                      height: "auto",
                    }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          <CircularProgress />
        )}
      </>
      <div className="ostabs">
        <div
          className={activeTab === "bill" ? "ostab active" : "ostab"}
          onClick={() => handleTabClick("bill")}
        >
          Bill Wise
        </div>
        <div
          className={activeTab === "party" ? "ostab active" : "ostab"}
          onClick={() => handleTabClick("party")}
        >
          Party Summary
        </div>
        <div
          className={activeTab === "monthly" ? "ostab active" : "ostab"}
          onClick={() => handleTabClick("monthly")}
        >
          Monthly Breakup
        </div>
        {/* Add more tabs as needed */}
      </div>
      <div className="ostab-content ">
        {activeTab === "bill" && (
          <>
            {!isLoading2 ? (
              <div id="table-container" className="table-container">
                {showTable && (
                  <table id="delTable" className="responsive-table"  ref={contentRef}>
                    <thead>
                      <tr className="sticky3">
                        <th className="sticky">Inv no</th>
                        <th onClick={() => sortTable('invdate')}>Inv Date{sortConfig.key === "invdate" &&
                          (sortConfig.direction === "asc" ? "▲" : "▼")}</th>
                          <th onClick={()=> sortTable('buyer')}>Buyer{sortConfig.key === "buyer" &&
                            (sortConfig.direction === "asc" ? "▲" : "▼")}</th>
                        <th>Inv Amt</th>
                        <th>Part Recd</th>
                        <th>O/S Amt</th>
                        <th>Days</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedData.map((row, index) => (
                        <tr key={row.invno}>
                          <td
                            className="sticky"
                            style={{
                              cursor: "pointer",
                              fontWeight: 500,
                              textDecoration: "underline",
                              textDecorationColor: "#0B2B43",
                            }}
                            onClick={() => {
                              const dateParts = row.invdate.split("-");
                              const date = new Date(
                                `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
                              );                  
                              const aprilFirst2024 = new Date("2024-04-01");               
                              const isAfterAprilFirst = date >= aprilFirst2024;
                              if (
                                row.netamt != 0 &&
                                isAfterAprilFirst
                              ) {
                                download(row.invno.split("/")[0]);
                              }
                            }}
                          >
                            <strong>{row.invno.split("/")[0]}</strong>
                          </td>
                          <td>{row.invdate.split("-").join("/")}</td>
                          <td>{row.buyer.trim()}</td>
                          <td style={{ textAlign: "right" }}>
                            {row.netamt.toLocaleString("en-IN")}
                          </td>
                          {/* <td>{row.remark}</td> */}
                          {/* <td className="remark-lr">
                    {row.remark &&
                      row.remark
                        .split(",")
                        .map((item, index) => <pre key={index}>{item}</pre>)}
                  </td> */}
                          <td style={{ textAlign: "right" }}>
                            {row.partpmnt.toLocaleString("en-IN")}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {row.balamt.toLocaleString("en-IN")}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {row.latedays.toLocaleString("en-IN")}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            )}
          </>
        )}
        {activeTab === "party" && (
          <>
            {!isLoading2 ? (
              <div id="table-container" className="table-container">
                {showTable && (
                  <table id="delTable" className="responsive-table"  ref={contentRef}>
                    <thead>
                      <tr className="sticky3">
                        <th
                          className="sticky"
                          onClick={() => sortTableP("buyer")}
                        >
                          Buyer
                          {sortConfigP.key === "buyer" &&
                            (sortConfigP.direction === "asc" ? "▲" : "▼")}
                        </th>
                        <th onClick={() => sortTableP("netamt")}>
                          Inv Amt
                          {sortConfigP.key === "netamt" &&
                            (sortConfigP.direction === "asc" ? "▲" : "▼")}
                        </th>
                        <th onClick={() => sortTableP("partpmnt")}>
                          Part Recd
                          {sortConfigP.key === "partpmnt" &&
                            (sortConfigP.direction === "asc" ? "▲" : "▼")}
                        </th>
                        <th onClick={() => sortTableP("osamt")}>
                          O/S Amt
                          {sortConfigP.key === "osamt" &&
                            (sortConfigP.direction === "asc" ? "▲" : "▼")}
                        </th>
                        <th
                          style={{ backgroundColor: "green" }}
                          onClick={() => sortTableP("amt1")}
                        >
                          0-30 Days
                          {sortConfigP.key === "amt1" &&
                            (sortConfigP.direction === "asc" ? "▲" : "▼")}
                        </th>
                        <th
                          style={{ backgroundColor: "blue" }}
                          onClick={() => sortTableP("amt2")}
                        >
                          31-60 Days
                          {sortConfigP.key === "amt2" &&
                            (sortConfigP.direction === "asc" ? "▲" : "▼")}
                        </th>
                        <th
                          style={{ backgroundColor: "red" }}
                          onClick={() => sortTableP("amt3")}
                        >
                          61-90 Days
                          {sortConfigP.key === "amt3" &&
                            (sortConfigP.direction === "asc" ? "▲" : "▼")}
                        </th>
                        <th
                          style={{ backgroundColor: "maroon" }}
                          onClick={() => sortTableP("amt4")}
                        >
                          91 & above
                          {sortConfigP.key === "amt4" &&
                            (sortConfigP.direction === "asc" ? "▲" : "▼")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedDataP.map((row, index) => (
                        <tr key={row.buyer}>
                          <td className="sticky"><strong>{row.buyer}</strong></td>
                          <td style={{ textAlign: "right" }}>
                            {row.netamt.toLocaleString("en-IN")}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {row.partpmnt.toLocaleString("en-IN")}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {row.osamt.toLocaleString("en-IN")}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              color: "green",
                              fontWeight: "bolder",
                            }}
                          >
                            {row.amt1.toLocaleString("en-IN") == 0
                              ? "-"
                              : row.amt1.toLocaleString("en-IN")}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              fontWeight: "bolder",
                              color: "blue",
                            }}
                          >
                            {row.amt2.toLocaleString("en-IN") == 0
                              ? "-"
                              : row.amt2.toLocaleString("en-IN")}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              fontWeight: "bolder",
                              color: "red",
                            }}
                          >
                            {row.amt3.toLocaleString("en-IN") == 0
                              ? "-"
                              : row.amt3.toLocaleString("en-IN")}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              fontWeight: "bolder",
                              color: "maroon",
                            }}
                          >
                            {row.amt4.toLocaleString("en-IN") == 0
                              ? "-"
                              : row.amt4.toLocaleString("en-IN")}
                          </td>
                        </tr>
                      ))}
                      <tr
                        style={{
                          backgroundColor: "#0B2B43",
                          color: "white",
                          fontWeight: "bolder",
                        }}
                      >
                        <td>Total: </td>
                        <td style={{ textAlign: "right" }}>
                          {partyData
                            .reduce((acc, obj) => acc + parseFloat(obj.netamt), 0)
                            .toLocaleString("en-IN")}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {partyData
                            .reduce((acc, obj) => acc + parseFloat(obj.partpmnt), 0)
                            .toLocaleString("en-IN")}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {partyData
                            .reduce((acc, obj) => acc + parseFloat(obj.osamt), 0)
                            .toLocaleString("en-IN")}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {partyData
                            .reduce((acc, obj) => acc + parseFloat(obj.amt1), 0)
                            .toLocaleString("en-IN")}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {partyData
                            .reduce((acc, obj) => acc + parseFloat(obj.amt2), 0)
                            .toLocaleString("en-IN")}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {partyData
                            .reduce((acc, obj) => acc + parseFloat(obj.amt3), 0)
                            .toLocaleString("en-IN")}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {partyData
                            .reduce((acc, obj) => acc + parseFloat(obj.amt4), 0)
                            .toLocaleString("en-IN")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            )}
          </>
        )}
        {activeTab === "monthly" && (
          <>
            {!isLoading2 ? (
              <div className="monthoscroll">
                <div className="monthos">
                  <div id="table-container" className="table-container">
                    {showTable && (
                      <table id="delTable" className="responsive-table"  ref={contentRef}>
                        <thead>
                          <tr className="sticky3">
                            <th className="sticky">Month</th>
                            <th>Amount</th>
                            <th>Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                          {monthlyData.map((row, index) => (
                            <tr key={row.invno}>
                              <td className="sticky">
                              <strong>{row.month}-{row.year}</strong>
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {row.total_balance.toLocaleString("en-IN")}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {row.cumulative_balance.toLocaleString("en-IN")}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div
                    className="monthosgraph"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "start",
                      width: "50%",
                    }}
                  >
                    <BarGraph  data={monthlyData} />
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            )}
          </>
        )}
      </div>
      <div id="footer" className="footer">
        <div className="total-details">
          <ul>
            <li>Total: {filteredData.length}</li>
            <li>
              Total InvAmt :{" "}
              {filteredData
                .reduce((acc, obj) => acc + parseFloat(obj.netamt), 0)
                .toLocaleString("en-IN")}
            </li>
            <li>
              Total PartRecd :{" "}
              {filteredData
                .reduce((acc, obj) => acc + parseFloat(obj.partpmnt), 0)
                .toLocaleString("en-IN")}
            </li>
            <li>
              Total O/SAmt :{" "}
              {filteredData
                .reduce((acc, obj) => acc + parseFloat(obj.balamt), 0)
                .toLocaleString("en-IN")}
            </li>
          </ul>
        </div>
        {!tableLoading && (
          <div>
            <button
              className="footbut exbut"
              onClick={exportToExcel}
              style={{
                width: "80px",
                margin: 0,
                fontSize: "12px",
                padding: "10px",
                height: "auto",
              }}
            >
              Excel
            </button>
            <button
            className="footbut"
            onClick={handleDownloadPDF}
            style={{
              width: '80px',
              margin: 0,
              fontSize: '12px',
              padding: '10px',
              height: 'auto',
            }}
          >
            Download
          </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyOut;
