import React, { useEffect, useRef, useState } from "react";
import "./dashboard.css";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import * as XLSX from "xlsx";
import { FilterBox } from "./Dashboard";
import Select, { components } from "react-select";
import html2pdf from "html2pdf.js";
import axiosInstance from "../api/axios";

const SaleReg = ({
  accounts,
  sortno,
  ptype,
  tradeName,
  shadeno,
  serverurl,
  usertype,
  blist
}) => {
  const [tdata, setTdata] = useState([]);
  const [partyData, setPartyData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [quaterlyData, setQuaterlyData] = useState([]);
  const [hydata, setHydata] = useState([]);
  const [iwisesum, setIwisesum] = useState([]);
  const [dwisesum, setDwisesum] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [tn, setTn] = useState("");
  const [sn, setSn] = useState("");
  const [shn, setShn] = useState("");
  const [pn, setpn] = useState("");
  const [buy, setBuy] = useState("");
  const [broker, setBroker] = useState("");
  const [sdesign, setSdesign] = useState("");
  const [dateS, setDateS] = useState("xxxx-xx-xx");
  const [dateE, setDateE] = useState("xxxx-xx-xx");
  const [showTable, setShowTable] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [showFbtn, setShowFbtn] = useState(true);
  const contentRef = useRef(null);
  const [activeTab, setActiveTab] = useState("bill");
  const selectref = useRef(null);
  const [sortConfig, setSortConfig] = useState({
    key: "buyer",
    direction: "asc",
  });

  const sortTable = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = [...partyData].sort((a, b) => {
    const keyA = a[sortConfig.key];
    const keyB = b[sortConfig.key];
    // Custom comparison for date column
    if (sortConfig.key === "date") {
      const dateA = new Date(keyA.split("-").reverse().join("-"));
      const dateB = new Date(keyB.split("-").reverse().join("-"));
      return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
    }
    if (
      (typeof keyA === "number" && typeof keyB === "number") ||
      (!isNaN(keyA) && !isNaN(keyB))
    ) {
      return sortConfig.direction === "asc" ? keyA - keyB : keyB - keyA;
    }
    // Default comparison for other columns
    return sortConfig.direction === "asc"
      ? keyA.localeCompare(keyB)
      : keyB.localeCompare(keyA);
  });

  const [sortConfigb, setSortConfigb] = useState({
    key: "invdate",
    direction: "desc",
  });

  const sortTableb = (key) => {
    let direction = "asc";
    if (sortConfigb.key === key && sortConfigb.direction === "asc") {
      direction = "desc";
    }
    setSortConfigb({ key, direction });
  };

  const sortedDatab = [...tdata].sort((a, b) => {
    const keyA = a[sortConfigb.key];
    const keyB = b[sortConfigb.key];
    // Custom comparison for date column
    if (sortConfigb.key === "invdate") {
      const dateA = new Date(keyA.split("-").reverse().join("-"));
      const dateB = new Date(keyB.split("-").reverse().join("-"));
      return sortConfigb.direction === "asc" ? dateA - dateB : dateB - dateA;
    }
    if (
      (typeof keyA === "number" && typeof keyB === "number") ||
      (!isNaN(keyA) && !isNaN(keyB))
    ) {
      return sortConfigb.direction === "asc" ? keyA - keyB : keyB - keyA;
    }
    // Default comparison for other columns
    return sortConfigb.direction === "asc"
      ? keyA.localeCompare(keyB)
      : keyB.localeCompare(keyA);
  });

  const [sortConfigsn, setSortConfigsn] = useState({
    key: "totalNetAmount",
    direction: "desc",
  });

  const sortTablesn = (key) => {
    let direction = "asc";
    if (sortConfigsn.key === key && sortConfigsn.direction === "asc") {
      direction = "desc";
    }
    setSortConfigsn({ key, direction });
  };

  const sortedDatasn = [...iwisesum].sort((a, b) => {
    const keyA = a[sortConfigsn.key];
    const keyB = b[sortConfigsn.key];
    // Custom comparison for date column
    if (sortConfigsn.key === "invdate") {
      const dateA = new Date(keyA.split("-").reverse().join("-"));
      const dateB = new Date(keyB.split("-").reverse().join("-"));
      return sortConfigsn.direction === "asc" ? dateA - dateB : dateB - dateA;
    }
    if (
      (typeof keyA === "number" && typeof keyB === "number") ||
      (!isNaN(keyA) && !isNaN(keyB))
    ) {
      return sortConfigsn.direction === "asc" ? keyA - keyB : keyB - keyA;
    }
    // Default comparison for other columns
    return sortConfigsn.direction === "asc"
      ? keyA.localeCompare(keyB)
      : keyB.localeCompare(keyA);
  });

  const [sortConfigtn, setSortConfigtn] = useState({
    key: "totalNetAmount",
    direction: "desc",
  });

  const sortTabletn = (key) => {
    let direction = "asc";
    if (sortConfigtn.key === key && sortConfigtn.direction === "asc") {
      direction = "desc";
    }
    setSortConfigtn({ key, direction });
  };

  const sortedDatatn = [...dwisesum].sort((a, b) => {
    const keyA = a[sortConfigtn.key];
    const keyB = b[sortConfigtn.key];
    // Custom comparison for date column
    if (sortConfigtn.key === "invdate") {
      const dateA = new Date(keyA.split("-").reverse().join("-"));
      const dateB = new Date(keyB.split("-").reverse().join("-"));
      return sortConfigtn.direction === "asc" ? dateA - dateB : dateB - dateA;
    }
    if (
      (typeof keyA === "number" && typeof keyB === "number") ||
      (!isNaN(keyA) && !isNaN(keyB))
    ) {
      return sortConfigtn.direction === "asc" ? keyA - keyB : keyB - keyA;
    }
    // Default comparison for other columns
    return sortConfigtn.direction === "asc"
      ? keyA.localeCompare(keyB)
      : keyB.localeCompare(keyA);
  });

  const buyerOptions = accounts.map((b) => ({ value: b, label: b }));
  const snOptions = sortno.map((b) => ({ value: b, label: b }));
  const tnOptions = tradeName.map((b) => ({ value: b, label: b }));
  const pOptions = ptype.map((b) => ({ value: b, label: b }));
  const shnOptions = shadeno.map((b) => ({ value: b, label: b }));
  const brokerOptions = blist.map((b) => ({ value: b, label: b }));

  const fetchInitialData = async () => {
    try {
      setTableLoading(true);
      setIsLoading2(true);
      const response = await axiosInstance.post(`/api/data/salereg`, {
        buyerNames: accounts.map((item) => item.trim()),
      });
      const mySet = new Set(response.data.tdata.map((obj) => obj.salekey));
      const uniqueList = Array.from(mySet, (salekey) =>
        response.data.tdata.find((obj) => obj.salekey === salekey)
      );

      setTdata(uniqueList);
      console.log("hi", uniqueList);
      const convertedData = aggregateSalesData(uniqueList);
      setPartyData(convertedData);
      const { monthlySummary, quarterlySummary, halfYearlySummary } =
        createMonthlySummary(uniqueList);
      setMonthlyData(monthlySummary);
      setQuaterlyData(quarterlySummary);
      setHydata(halfYearlySummary);
      const summar = generateItemSummary(response.data.newdata);
      setIwisesum(summar);
      const summa = generateDesignSummary(response.data.newdata);
      setDwisesum(summa);
      setTableLoading(false);
      setIsLoading2(false);
      setShowTable(true);
      setTimeout(() => {
        const footerElement = document.getElementById("footer");
        const tableContainerElement =
          document.getElementById("table-container");

        if (footerElement && tableContainerElement) {
          const footerOffset = footerElement.offsetTop;
          const tableContainerOffset = tableContainerElement.offsetTop;
          const calculatedHeight = footerOffset - tableContainerOffset - 2;
          tableContainerElement.style.height = `${calculatedHeight}px`;
        }
      }, 250);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadPDF = () => {
    if (contentRef.current) {
      const printableContent = contentRef.current;
      const width = printableContent.scrollWidth; // Get the width of the table
      const additionalSpace = window.innerWidth < 900 ? 200 : 0; // Check device width
      console.log(additionalSpace);

      html2pdf()
        .set({
          margin: 1,
          filename: `${activeTab}-Bill/LR.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2, width: width + additionalSpace }, // Set width + additional space
          jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
        })
        .from(printableContent)
        .save();
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.keyCode === 13) {
        const button = document.getElementById("search");
        if (button) {
          button.click();
        }
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const footerElement = document.getElementById("footer");
      const tableContainerElement = document.getElementById("table-container");

      if (footerElement && tableContainerElement) {
        const footerOffset = footerElement.offsetTop;
        const tableContainerOffset = tableContainerElement.offsetTop;
        const calculatedHeight = footerOffset - tableContainerOffset - 2;
        tableContainerElement.style.height = `${calculatedHeight}px`;
        console.log(footerOffset, tableContainerOffset, calculatedHeight);
      }
    }, 200);
  }, [activeTab, showFilters]);

  const fetchFilteredData = async () => {
    setShowFbtn(true);
    setShowFilters(false);
    setIsLoading2(true);
    try {
      const response = await axiosInstance.post(
        `/api/data/fsalereg/${tn == "" ? "x" : tn}/${
          sn == "" ? "x" : sn
        }/${encodeURIComponent(shn == "" ? "x" : shn)}/${pn == "" ? "x" : pn}/${
          buy == "" ? "x" : buy
        }/${encodeURIComponent(dateS)}/${encodeURIComponent(dateE)}/${broker == "" ? "x" : broker}`,
        {
          buyerNames: accounts.map((item) => item.trim()),
        }
      );
      const mySet = new Set(response.data.tdata[0].map((obj) => obj.salekey));
      const uniqueList = Array.from(mySet, (salekey) =>
        response.data.tdata[0].find((obj) => obj.salekey === salekey)
      );
      setTdata(uniqueList);
      const convertedData = aggregateSalesData(uniqueList);
      setPartyData(convertedData);
      const { monthlySummary, quarterlySummary, halfYearlySummary } =
        createMonthlySummary(uniqueList);
      setMonthlyData(monthlySummary);
      setQuaterlyData(quarterlySummary);
      setHydata(halfYearlySummary);
      const summar = generateItemSummary(response.data.newdata);
      setIwisesum(summar);
      const summa = generateDesignSummary(response.data.newdata);
      setDwisesum(summa);
      setShowTable(true);
      setIsLoading2(false);
      setTimeout(() => {
        const footerElement = document.getElementById("footer");
        const tableContainerElement =
          document.getElementById("table-container");

        if (footerElement && tableContainerElement) {
          const footerOffset = footerElement.offsetTop;
          const tableContainerOffset = tableContainerElement.offsetTop;
          const calculatedHeight = footerOffset - tableContainerOffset - 2;
          tableContainerElement.style.height = `${calculatedHeight}px`;
        }
      }, 250);
    } catch (error) {
      console.log(error);
    }
  };

  const download = async (no) => {
    try {
      const response = await axiosInstance.get(`/api/data/download-pdf/${no}`, {
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${no}-SB.pdf`);

      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      // Handle error
    }
  };

  const downloadl = async (no) => {
    try {
      const response2 = await axiosInstance.get(
        `/api/data/download-lpdf/${no}`,
        {
          responseType: "blob",
        }
      );

      const blob1 = new Blob([response2.data], { type: "image/jpeg" });
      const url1 = window.URL.createObjectURL(blob1);

      const link1 = document.createElement("a");
      link1.href = url1;
      link1.setAttribute("download", `${no}-LR.jpg`);

      document.body.appendChild(link1);
      link1.click();

      window.URL.revokeObjectURL(url1);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      // Handle error
    }
  };

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.table_to_sheet(
      document.getElementById("delTable")
    );
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `My Deliveries.xlsx`);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  function aggregateSalesData(originalData) {
    const aggregatedData = {};

    // Aggregate sales data by buyer
    originalData.forEach((sale) => {
      if (!aggregatedData[sale.buyer]) {
        aggregatedData[sale.buyer] = {
          buyer: sale.buyer,
          invno_count: 0,
          netamt_sum: 0,
          pcs_sum: 0,
          qty_sum: 0,
        };
      }

      aggregatedData[sale.buyer].invno_count++;
      aggregatedData[sale.buyer].netamt_sum += parseFloat(sale.netamt);
      aggregatedData[sale.buyer].pcs_sum += parseFloat(sale.pcs);
      aggregatedData[sale.buyer].qty_sum += parseFloat(sale.qty);
    });

    // Convert aggregated data to array of objects
    const result = Object.values(aggregatedData);
    return result;
  }

  function createMonthlySummary(originalData) {
    // Initialize an object to store monthly summaries
    const monthlySummary = {
      Apr: { pcs: 0, qty: 0, netamt: 0 },
      May: { pcs: 0, qty: 0, netamt: 0 },
      Jun: { pcs: 0, qty: 0, netamt: 0 },
      Jul: { pcs: 0, qty: 0, netamt: 0 },
      Aug: { pcs: 0, qty: 0, netamt: 0 },
      Sep: { pcs: 0, qty: 0, netamt: 0 },
      Oct: { pcs: 0, qty: 0, netamt: 0 },
      Nov: { pcs: 0, qty: 0, netamt: 0 },
      Dec: { pcs: 0, qty: 0, netamt: 0 },
      Jan: { pcs: 0, qty: 0, netamt: 0 },
      Feb: { pcs: 0, qty: 0, netamt: 0 },
      Mar: { pcs: 0, qty: 0, netamt: 0 },
    };

    // Loop through the original data and update the monthly summary
    originalData.forEach((sale) => {
      const dateParts = sale.invdate.split("-");
      const month = parseInt(dateParts[1], 10);      
      const pcs = parseFloat(sale.pcs);
      const qty = parseFloat(sale.qty);
      const netamt = parseFloat(sale.netamt);
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const monthName = monthNames[month - 1]; // Subtract 1 because months are zero-based in JavaScript      
      monthlySummary[monthName].pcs += pcs;      
      monthlySummary[monthName].qty += qty;
      monthlySummary[monthName].netamt += netamt;
    });

    // Convert the monthly summary object into an array of objects
    const monthlySummaryArray = Object.keys(monthlySummary).map((month) => ({
      Month: month,
      pcs: monthlySummary[month].pcs,
      qty: monthlySummary[month].qty,
      netamt: monthlySummary[month].netamt,
    }));

    const quarterlySummary = [
      { Quarter: "Q1", pcs: 0, qty: 0, netamt: 0 },
      { Quarter: "Q2", pcs: 0, qty: 0, netamt: 0 },
      { Quarter: "Q3", pcs: 0, qty: 0, netamt: 0 },
      { Quarter: "Q4", pcs: 0, qty: 0, netamt: 0 },
    ];

    const halfYearlySummary = [
      { Quarter: "H1", pcs: 0, qty: 0, netamt: 0 },
      { Quarter: "H2", pcs: 0, qty: 0, netamt: 0 },
    ];

    halfYearlySummary[0].pcs = monthlySummaryArray
      .slice(0, 6)
      .reduce((sum, month) => sum + month.pcs, 0);
    halfYearlySummary[0].qty = monthlySummaryArray
      .slice(0, 6)
      .reduce((sum, month) => sum + month.qty, 0);
    halfYearlySummary[0].netamt = monthlySummaryArray
      .slice(0, 6)
      .reduce((sum, month) => sum + month.netamt, 0);
    halfYearlySummary[1].pcs = monthlySummaryArray
      .slice(6, 12)
      .reduce((sum, month) => sum + month.pcs, 0);
    halfYearlySummary[1].qty = monthlySummaryArray
      .slice(6, 12)
      .reduce((sum, month) => sum + month.qty, 0);
    halfYearlySummary[1].netamt = monthlySummaryArray
      .slice(6, 12)
      .reduce((sum, month) => sum + month.netamt, 0);

    quarterlySummary[0].pcs = monthlySummaryArray
      .slice(0, 3)
      .reduce((sum, month) => sum + month.pcs, 0);
    quarterlySummary[0].qty = monthlySummaryArray
      .slice(0, 3)
      .reduce((sum, month) => sum + month.qty, 0);
    quarterlySummary[0].netamt = monthlySummaryArray
      .slice(0, 3)
      .reduce((sum, month) => sum + month.netamt, 0);
    quarterlySummary[1].pcs = monthlySummaryArray
      .slice(3, 6)
      .reduce((sum, month) => sum + month.pcs, 0);
    quarterlySummary[1].qty = monthlySummaryArray
      .slice(3, 6)
      .reduce((sum, month) => sum + month.qty, 0);
    quarterlySummary[1].netamt = monthlySummaryArray
      .slice(3, 6)
      .reduce((sum, month) => sum + month.netamt, 0);
    quarterlySummary[2].pcs = monthlySummaryArray
      .slice(6, 9)
      .reduce((sum, month) => sum + month.pcs, 0);
    quarterlySummary[2].qty = monthlySummaryArray
      .slice(6, 9)
      .reduce((sum, month) => sum + month.qty, 0);
    quarterlySummary[2].netamt = monthlySummaryArray
      .slice(6, 9)
      .reduce((sum, month) => sum + month.netamt, 0);
    quarterlySummary[3].pcs = monthlySummaryArray
      .slice(9, 12)
      .reduce((sum, month) => sum + month.pcs, 0);
    quarterlySummary[3].qty = monthlySummaryArray
      .slice(9, 12)
      .reduce((sum, month) => sum + month.qty, 0);
    quarterlySummary[3].netamt = monthlySummaryArray
      .slice(9, 12)
      .reduce((sum, month) => sum + month.netamt, 0);

    return {
      monthlySummary: monthlySummaryArray,
      quarterlySummary,
      halfYearlySummary,
    };
  }

  const generateItemSummary = (data) => {
    // Create a map to store item-wise summary
    const summaryMap = new Map();

    // Iterate through the fetched data and calculate summary
    data.forEach((item) => {
      const { item: itemName, qty, netamt } = item;
      if (itemName) {
        if (!summaryMap.has(itemName)) {
          summaryMap.set(itemName, { totalQuantity: 0, totalNetAmount: 0 });
        }
        const summary = summaryMap.get(itemName);
        summary.totalQuantity += parseFloat(qty);
        summary.totalNetAmount += parseFloat(netamt);
        summaryMap.set(itemName, summary);
      }
    });

    // Convert map to array for easier rendering
    const summaryArray = Array.from(summaryMap, ([itemName, summary]) => ({
      itemName,
      totalQuantity: summary.totalQuantity,
      totalNetAmount: summary.totalNetAmount,
    }));

    return summaryArray;
  };

  useEffect(() => {
    const fetchFilteredData1 = async () => {
      setShowFbtn(true);
      setShowFilters(false);
      setIsLoading2(true);
      const apiq = usertype == 'Customer' ?  `/api/data/fsalereg/${
        sdesign == "" ? (tn == "" ? "x" : tn) : sdesign
      }/${sn == "" ? "x" : sn}/${encodeURIComponent(shn == "" ? "x" : shn)}/${
        pn == "" ? "x" : pn
      }/${buy == "" ? "x" : buy}/${encodeURIComponent(
        dateS
      )}/${encodeURIComponent(dateE)}/${broker == "" ? "x" : broker}`: `/api/data/fsalereg/${tn == "" ? "x" : tn}/${
            sn == "" ? "x" : sn
          }/${encodeURIComponent(shn == "" ? "x" : shn)}/${pn == "" ? "x" : pn}/${
            sdesign == "" ? (buy == "" ? "x" : buy) : sdesign
          }/${encodeURIComponent(dateS)}/${encodeURIComponent(dateE)}/${broker == "" ? "x" : broker}`;
      try {
        const response = await axiosInstance.post(apiq, {
          buyerNames: accounts.map((item) => item.trim()),
        });
        const mySet = new Set(response.data.tdata[0].map((obj) => obj.salekey));
        const uniqueList = Array.from(mySet, (salekey) =>
          response.data.tdata[0].find((obj) => obj.salekey === salekey)
        );
        setTdata(uniqueList);
        const convertedData = aggregateSalesData(uniqueList);
        setPartyData(convertedData);
        const { monthlySummary, quarterlySummary, halfYearlySummary } =
          createMonthlySummary(uniqueList);
        setMonthlyData(monthlySummary);
        setQuaterlyData(quarterlySummary);
        setHydata(halfYearlySummary);
        const summar = generateItemSummary(response.data.newdata);
        setIwisesum(summar);
        const summa = generateDesignSummary(response.data.newdata);
        setDwisesum(summa);
        setShowTable(true);
        setIsLoading2(false);
        setTimeout(() => {
          const footerElement = document.getElementById("footer");
          const tableContainerElement =
            document.getElementById("table-container");

          if (footerElement && tableContainerElement) {
            const footerOffset = footerElement.offsetTop;
            const tableContainerOffset = tableContainerElement.offsetTop;
            const calculatedHeight = footerOffset - tableContainerOffset - 2;
            tableContainerElement.style.height = `${calculatedHeight}px`;
          }
        }, 250);
      } catch (error) {
        console.log(error);
      }
    };
    fetchFilteredData1();
  }, [sdesign]);

  const generateDesignSummary = (data) => {
    // Create a map to store design-wise summary
    const summaryMap = new Map();

    // Iterate through the fetched data and calculate summary
    data.forEach((item) => {
      const { item: itemName, design, qty, netamt } = item;
      if (design) {
        const key = `${itemName} - ${design}`;
        if (!summaryMap.has(key)) {
          summaryMap.set(key, {
            totalQuantity: 0,
            totalNetAmount: 0,
            item: itemName,
            design,
          });
        }
        const summary = summaryMap.get(key);
        summary.totalQuantity += parseFloat(qty);
        summary.totalNetAmount += parseFloat(netamt);
        summaryMap.set(key, summary);
      }
    });

    // Convert map to array for easier rendering
    // const summaryArray = Array.from(summaryMap, ([design, summary]) => ({
    //     design,
    //     totalQuantity: summary.totalQuantity,
    //     totalNetAmount: summary.totalNetAmount,
    //     item: summary.itemName
    // }));

    const summaryArray = Array.from(summaryMap.values());

    return summaryArray;
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const CustomControl = (props) => (
    <components.Control {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        height={"20px"}
        width={"20px"}
      >
        <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
      </svg>
      {props.children}
    </components.Control>
  );

  const soptions = usertype == "Customer" ? tnOptions : buyerOptions;

  return (
    <div className="container">
      <>
        {!tableLoading && (
          <>
            {showFbtn && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                {!(
                  dateE != "xxxx-xx-xx" ||
                  dateE != "xxxx-xx-xx" ||
                  buy ||
                  sn ||
                  tn ||
                  shn ||
                  broker ||
                  pn
                ) && (
                  <div
                    className="filter-item cfi"
                    style={{ marginBottom: "0px", flex: "1" }}
                  >
                    <Select
                      ref={selectref}
                      value={
                        sdesign ? { value: sdesign, label: sdesign } : null
                      }
                      options={soptions.sort((a, b) =>
                        a.label.toString().localeCompare(b.label.toString())
                      )}
                      placeholder={"Search"}
                      isClearable={true}
                      components={{ Control: CustomControl }}
                      onChange={(option) => {
                        setSdesign(option == null ? "" : option.label);

                        if (option == null && selectref.current) {
                          selectref.current.blur();
                        }
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "100%",
                          paddingInlineStart: "10px",
                          borderRadius: "25px",
                          backgroundColor: "lightgrey",
                          textAlign: "left",
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          maxHeight: "200px",
                          textAlign: "left",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 6,
                        }),
                        container: (provided) => ({
                          ...provided,
                          width: "90%",
                        }),
                      }}
                    />
                  </div>
                )}
                <button
                  className="filter-btn"
                  onClick={() => {
                    if (
                      dateE != "xxxx-xx-xx" ||
                      dateE != "xxxx-xx-xx" ||
                      buy ||
                      sn ||
                      tn ||
                      shn ||
                      broker ||
                      pn
                    ) {
                      setDateE("xxxx-xx-xx");
                      setDateS("xxxx-xx-xx");
                      setBuy("");
                      setTn("");
                      setSn("");
                      setpn("");
                      setShn("");
                      setBroker("")
                      fetchInitialData();
                    } else {
                      setShowFilters(!showFilters);
                      setShowFbtn(false);
                    }
                  }}
                >
                  {dateE != "xxxx-xx-xx" ||
                  dateE != "xxxx-xx-xx" ||
                  buy ||
                  sn ||
                  tn ||
                  shn ||
                  broker ||
                  pn
                    ? "Clear"
                    : "More"}{" "}
                  Filters
                </button>
                <p
                  style={{
                    marginLeft: "5px",
                    color: "red",
                    fontStyle: "italic",
                  }}
                >
                  {dateE != "xxxx-xx-xx" ||
                  dateE != "xxxx-xx-xx" ||
                  buy ||
                  sn ||
                  tn ||
                  shn ||
                  broker ||
                  pn ? (
                    <>
                      Showing Results for: {dateS != "xxxx-xx-xx" && `${dateS}`}{" "}
                      {dateE != "xxxx-xx-xx" && `${dateE}`} {buy && `"${buy}"`}{" "}
                      {sn && `"${sn}"`} {tn && `"${tn}"`} {shn && `"${shn}"`}{" "}
                      {pn && `"${pn}"`} {broker && `"${broker}"`}{" "}
                    </>
                  ) : (
                    <></>
                  )}
                </p>
              </div>
            )}
            {showFilters && (
              <div className="filter-boxes">
                <div className="filter-item">
                  <p
                    onClick={() => {
                      setShowFbtn(true);
                      setShowFilters(false);
                    }}
                    style={{
                      cursor: "pointer",
                      border: "2px solid #0B2B43",
                      borderRadius: "50%",
                      padding: "5px 10px 5px 10px",
                      color: "white",
                      backgroundColor: "#0B2B43",
                    }}
                  >
                    X
                  </p>
                </div>
                <div className="filter-item filter-item2 ">
                  <input
                    className="filterip"
                    type="date"
                    placeholder="Date[FROM]"
                    value={dateS}
                    onChange={(e) => {
                      setDateS(e.target.value);
                      setShowTable(false);
                    }}
                    style={{
                      width: "150px",
                      padding: "8px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      height: "33.45px",
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                  className="filter-item filter-item2"
                >
                  <p
                    style={{
                      marginRight: "15px",
                    }}
                  >
                    To
                  </p>
                  <input
                    className="filterip fip2"
                    type="date"
                    placeholder="DATE[TO]"
                    value={dateE}
                    onFocus={(event) => event.target.select()}
                    onChange={(e) => {
                      setDateE(e.target.value);
                      setShowTable(false);
                    }}
                    style={{
                      width: "150px",
                      padding: "8px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      height: "33.46px",
                    }}
                  />
                </div>
                <div className="filter-item filter-item2">
                  <Select
                    value={buy ? { value: buy, label: buy } : null}
                    options={buyerOptions.sort((a, b) =>
                      a.label.localeCompare(b.label)
                    )}
                    placeholder={"Buyer"}
                    isClearable={true}
                    onChange={(option) => {
                      setBuy(option == null ? "" : option.label);
                      setShowTable(false);
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "150px", // Adjust width as needed
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        maxHeight: "200px", // Adjust height as needed
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 6,
                      }),
                    }}
                  />
                </div>
                <div className="filter-item filter-item2">
                  <Select
                    value={broker ? { value: broker, label: broker } : null}
                    options={brokerOptions.sort((a, b) =>
                      a.label.localeCompare(b.label)
                    )}
                    placeholder={"Broker"}
                    isClearable={true}
                    onChange={(option) => {
                      setBroker(option == null ? "" : option.label);
                      setShowTable(false);
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "150px", // Adjust width as needed
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        maxHeight: "200px", // Adjust height as needed
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 6,
                      }),
                    }}
                  />
                </div>
                <div className="filter-item sn filter-item2">
                  <Select
                    value={sn ? { value: sn, label: sn } : null}
                    options={snOptions.sort((a, b) =>
                      a.label.localeCompare(b.label)
                    )}
                    placeholder={"Item"}
                    isClearable={true}
                    onChange={(option) => {
                      setSn(option == null ? "" : option.label);
                      setShowTable(false);
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "150px", // Adjust width as needed
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        maxHeight: "200px", // Adjust height as needed
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 6,
                      }),
                    }}
                  />
                </div>
                <div className="filter-item tn filter-item2">
                  <Select
                    value={tn ? { value: tn, label: tn } : null}
                    options={tnOptions.sort((a, b) =>
                      a.label.localeCompare(b.label)
                    )}
                    placeholder={"Design"}
                    isClearable={true}
                    onChange={(option) => {
                      setTn(option == null ? "" : option.label);
                      setShowTable(false);
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "150px", // Adjust width as needed
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        maxHeight: "200px", // Adjust height as needed
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 6,
                      }),
                    }}
                  />
                </div>
                <div className="filter-item sn filter-item2">
                  <Select
                    value={shn ? { value: shn, label: shn } : null}
                    options={shnOptions.sort((a, b) =>
                      a.label.localeCompare(b.label)
                    )}
                    placeholder={"Shade"}
                    isClearable={true}
                    onChange={(option) => {
                      setShn(option == null ? "" : option.label);
                      setShowTable(false);
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "150px", // Adjust width as needed
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        maxHeight: "200px", // Adjust height as needed
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 6,
                      }),
                    }}
                  />
                </div>
                <div className="filter-item filter-item2">
                  <Select
                    value={pn ? { value: pn, label: pn } : null}
                    options={pOptions.sort((a, b) =>
                      a.label.localeCompare(b.label)
                    )}
                    placeholder={"Pkg"}
                    isClearable={true}
                    onChange={(option) => {
                      setpn(option == null ? "" : option.label);
                      setShowTable(false);
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "150px", // Adjust width as needed
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        maxHeight: "200px", // Adjust height as needed
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 6,
                      }),
                    }}
                  />
                </div>
                <div className="filter-item filter-item2">
                  <button
                    id="search"
                    onClick={() => {
                      setSdesign("")
                      fetchFilteredData();
                    }}
                    style={{
                      width: "80px",
                      margin: 0,
                      fontSize: "12px",
                      padding: "10px",
                      height: "auto",
                    }}
                  >
                    Search
                  </button>
                  <button
                    onClick={() => {
                      setDateE("xxxx-xx-xx");
                      setDateS("xxxx-xx-xx");
                      setBuy("");
                      setTn("");
                      setSn("");
                      setpn("");
                      setBroker("")
                      setShn("");
                      fetchInitialData();
                    }}
                    style={{
                      width: "80px",
                      margin: 0,
                      fontSize: "12px",
                      padding: "10px",
                      height: "auto",
                    }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </>
      <div className="ostabs">
        <div
          className={activeTab === "bill" ? "ostab active" : "ostab"}
          onClick={() => handleTabClick("bill")}
        >
          Bill Wise
        </div>
        <div
          className={activeTab === "party" ? "ostab active" : "ostab"}
          onClick={() => handleTabClick("party")}
        >
          Party Summary
        </div>
        <div
          className={activeTab === "itwise" ? "ostab active" : "ostab"}
          onClick={() => handleTabClick("itwise")}
        >
          Item-wise
        </div>
        <div
          className={activeTab === "tnwise" ? "ostab active" : "ostab"}
          onClick={() => handleTabClick("tnwise")}
        >
          Design-wise
        </div>
        <div
          className={activeTab === "monthly" ? "ostab active" : "ostab"}
          onClick={() => handleTabClick("monthly")}
        >
          Monthly Summary
        </div>
      </div>
      <div className="ostab-content ">
        {activeTab === "bill" && (
          <>
            {!isLoading2 ? (
              <div id="table-container" className="table-container">
                {showTable && (
                  <table
                    id="delTable"
                    className="responsive-table"
                    ref={contentRef}
                  >
                    <thead>
                      <tr className="sticky3">
                        <th className="sticky">Inv no</th>
                        <th onClick={() => sortTableb("invdate")}>
                          Inv Date
                          {sortConfigb.key === "invdate" &&
                            (sortConfigb.direction === "asc" ? "▲" : "▼")}
                        </th>
                        <th onClick={() => sortTableb("buyer")}>
                          Buyer
                          {sortConfigb.key === "buyer" &&
                            (sortConfigb.direction === "asc" ? "▲" : "▼")}
                        </th>
                        <th onClick={() => sortTableb("netamt")}>
                          Net Amt
                          {sortConfigb.key === "netamt" &&
                            (sortConfigb.direction === "asc" ? "▲" : "▼")}
                        </th>
                        <th>
                          Item Dtl
                          <pre className="remark-head">
                            Design{"     "}Shd{"      "}Qty
                            {"        "}Pkg ;
                          </pre>
                        </th>
                        <th>Pcs</th>
                        <th>Qty</th>
                        <th>Tport</th>
                        <th>LR</th>
                        <th>LR Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedDatab.map((row, index) => (
                        <tr key={index}>
                          <td
                            className="sticky"
                            style={{
                              cursor: "pointer",
                              fontWeight: 500,
                              textDecoration: row.lrno.trim()
                                ? "underline"
                                : "none",
                              textDecorationColor: "#0B2B43",
                            }}
                            onClick={() => download(row.invno.split("/")[0])}
                          >
                            <strong>{row.invno.split("/")[0]}</strong>
                          </td>
                          <td>{row.invdate.split("-").join("/")}</td>
                          <td>{row.buyer.trim()}</td>
                          <td style={{ textAlign: "right" }}>
                            {row.netamt.toLocaleString("en-IN")}
                          </td>
                          {/* <td>{row.remark}</td> */}
                          <td className="remark-lr">
                            {row.remark &&
                              row.remark
                                .split(",")
                                .map((item, index) => (
                                  <pre key={index}>{item}</pre>
                                ))}
                          </td>
                          <td>{row.pcs}</td>
                          <td>{row.qty}</td>
                          <td>{row.tport.slice(0, 8)}</td>
                          <td
                            onClick={() => downloadl(row.invno.split("/")[0])}
                            style={{
                              textWrap: "nowrap",
                              cursor: "pointer",
                              fontWeight: 500,
                              textDecoration: row.lrno.trim()
                                ? "underline"
                                : "none",
                              textDecorationColor: "#0B2B43",
                            }}
                          >
                            <strong>{row.lrno.trim()}</strong>
                          </td>
                          <td>
                            {!row.lrdate.includes("-1901") &&
                              row.lrdate.split("-").join("/")}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            ) : (
              <CircularProgress />
            )}
          </>
        )}
        {activeTab === "party" && (
          <>
            <div id="table-container" className="table-container">
              {showTable && (
                <table
                  id="delTable"
                  className="responsive-table"
                  ref={contentRef}
                >
                  <thead>
                    <tr className="sticky3">
                      <th className="sticky">Tot. Inv</th>
                      <th onClick={() => sortTable("buyer")}>
                        Buyer
                        {sortConfig.key === "buyer" &&
                          (sortConfig.direction === "asc" ? "▲" : "▼")}
                      </th>
                      <th onClick={() => sortTable("netamt_sum")}>
                        Net Amt
                        {sortConfig.key === "netamt_sum" &&
                          (sortConfig.direction === "asc" ? "▲" : "▼")}
                      </th>
                      <th onClick={() => sortTable("pcs_sum")}>
                        Pcs
                        {sortConfig.key === "pcs_sum" &&
                          (sortConfig.direction === "asc" ? "▲" : "▼")}
                      </th>
                      <th onClick={() => sortTable("qty_sum")}>
                        Qty
                        {sortConfig.key === "qty_sum" &&
                          (sortConfig.direction === "asc" ? "▲" : "▼")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedData.map((row, index) => (
                      <tr key={row.buyer}>
                        <td className="sticky">{row.invno_count}</td>
                        <td><strong>{row.buyer}</strong></td>
                        <td className="rightalign">
                          {row.netamt_sum.toLocaleString("en-IN")}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {row.pcs_sum.toLocaleString("en-IN")}
                        </td>
                        <td>{row.qty_sum.toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </>
        )}
        {activeTab === "itwise" && (
          <>                    
            <div id="table-container" className="table-container">
              {showTable && (
                <table
                  id="delTable"
                  className="responsive-table"
                  ref={contentRef}
                >
                  <thead>
                    <tr className="sticky3">
                      <th
                        className="sticky"
                        onClick={() => sortTablesn("itemName")}
                      >
                        Item
                        {sortConfigsn.key === "itemName" &&
                          (sortConfigsn.direction === "asc" ? "▲" : "▼")}
                      </th>
                      <th onClick={() => sortTablesn("totalQuantity")}>
                        Total Qty
                        {sortConfigsn.key === "totalQuantity" &&
                          (sortConfigsn.direction === "asc" ? "▲" : "▼")}
                      </th>
                      <th onClick={() => sortTablesn("totalNetAmount")}>
                        Total NetAmt
                        {sortConfigsn.key === "totalNetAmount" &&
                          (sortConfigsn.direction === "asc" ? "▲" : "▼")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedDatasn.map((row, index) => (
                      <tr key={index}>
                        <td className="sticky">
                          <strong>{row.itemName}</strong>
                        </td>
                        <td className="rightalign">
                          {row.totalQuantity.toLocaleString("en-IN")}
                        </td>
                        <td className="rightalign">
                          {row.totalNetAmount.toLocaleString("en-IN")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </>
        )}
        {activeTab === "tnwise" && (
          <>
            <div id="table-container" className="table-container">
              {showTable && (
                <table
                  id="delTable"
                  className="responsive-table"
                  ref={contentRef}
                >
                  <thead>
                    <tr className="sticky3">
                      <th
                        className="sticky"
                        onClick={() => sortTabletn("item")}
                      >
                        Item
                        {sortConfigtn.key === "item" &&
                          (sortConfigtn.direction === "asc" ? "▲" : "▼")}
                      </th>
                      <th onClick={() => sortTabletn("design")}>
                        Design
                        {sortConfigtn.key === "design" &&
                          (sortConfigtn.direction === "asc" ? "▲" : "▼")}
                      </th>
                      <th onClick={() => sortTabletn("totalQuantity")}>
                        Total Qty
                        {sortConfigtn.key === "totalQuantity" &&
                          (sortConfigtn.direction === "asc" ? "▲" : "▼")}
                      </th>
                      <th onClick={() => sortTabletn("totalNetAmount")}>
                        Total NetAmt
                        {sortConfigtn.key === "totalNetAmount" &&
                          (sortConfigtn.direction === "asc" ? "▲" : "▼")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedDatatn.map((row, index) => (
                      <tr key={index}>
                        <td className="sticky">
                          <strong>{row.item}</strong>
                        </td>
                        <td>
                          <strong>{row.design}</strong>
                        </td>
                        <td className="rightalign">
                          {row.totalQuantity.toLocaleString("en-IN")}
                        </td>
                        <td className="rightalign">
                          {row.totalNetAmount.toLocaleString("en-IN")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </>
        )}
        {activeTab === "monthly" && (
          <>
            <div
              id="table-container"
              className="table-container mosfet"
              ref={contentRef}
            >
              {showTable && (
                <table id="delTable" className="responsive-table slt1">
                  <thead>
                    <tr className="sticky3">
                      <th className="sticky">Month</th>
                      <th>NetAmt</th>
                      <th>Pcs</th>
                      <th>Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {monthlyData.map((row, index) => (
                      <tr key={row.buyer}>
                        <td className="sticky">
                          <strong>{row.Month}</strong>
                        </td>
                        <td className="rightalign">
                          {row.netamt.toLocaleString("en-IN")}
                        </td>
                        <td className="rightalign">
                          {row.pcs.toLocaleString("en-IN")}
                        </td>
                        <td className="rightalign">
                          {row.qty.toLocaleString("en-IN")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {showTable && (
                <table id="delTable" className="responsive-table slt2">
                  <thead>
                    <tr className="sticky3">
                      <th>Quaterly</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quaterlyData.map((row, index) => (
                      <React.Fragment key={index}>
                        <tr style={{ backgroundColor: "lightgrey" }}>
                          <td>
                            <strong>{row.Quarter}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>NetAmt: {row.netamt.toLocaleString("en-IN")}</td>
                        </tr>
                        <tr>
                          <td>
                            Pcs: {row.pcs.toLocaleString("en-IN")} - Qty:{" "}
                            {row.qty.toLocaleString("en-IN")}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              )}
              {showTable && (
                <table id="delTable" className="responsive-table slt2">
                  <thead>
                    <tr className="sticky3">
                      <th>Half-Yearly</th>
                    </tr>
                  </thead>
                  <tbody>
                    {hydata.map((row, index) => (
                      <React.Fragment key={index}>
                        <tr style={{ backgroundColor: "lightgrey" }}>
                          <td>
                            <strong>{row.Quarter}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>NetAmt: {row.netamt.toLocaleString("en-IN")}</td>
                        </tr>
                        <tr>
                          <td>Pcs: {row.pcs.toLocaleString("en-IN")}</td>
                        </tr>
                        <tr>
                          <td>Qty: {row.qty.toLocaleString("en-IN")}</td>
                        </tr>
                        <tr>
                          <td>{"-"}</td>
                        </tr>
                        <tr>
                          <td>{"-"}</td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </>
        )}
      </div>
      <div id="footer" className="footer">
        <div className="total-details">
          <ul>
            <li>Total: {tdata.length}</li>
            <li>
              Total Pcs:{" "}
              {tdata
                .reduce((acc, obj) => acc + parseFloat(obj.pcs), 0)
                .toLocaleString("en-IN")}
            </li>
            <li>
              Total Qty:{" "}
              {tdata
                .reduce((acc, obj) => acc + parseFloat(obj.qty), 0)
                .toLocaleString("en-IN")}
            </li>
            <li>
              Total Amt:{" "}
              {tdata
                .reduce((acc, obj) => acc + parseInt(obj.netamt), 0)
                .toLocaleString("en-IN")}
            </li>
          </ul>
        </div>
        {!tableLoading && (
          <div>
            <button
              className="footbut"
              onClick={exportToExcel}
              style={{
                width: "80px",
                margin: 0,
                fontSize: "12px",
                padding: "10px",
                height: "auto",
              }}
            >
              Excel
            </button>
            <button
              className="footbut"
              onClick={handleDownloadPDF}
              style={{
                width: "80px",
                margin: 0,
                fontSize: "12px",
                padding: "10px",
                height: "auto",
              }}
            >
              Download
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SaleReg;
