import React, { useEffect, useState, useRef } from "react";
import "./print.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import html2pdf from "html2pdf.js";
import axiosInstance from '../api/axios';


const PrintForm = ({rowNumber}) => {
  // const location = useLocation();
  const no = rowNumber
  const contentRef = useRef(null);

  const [basicData, setBasicData] = useState({});
  const [printRows, setPrintRows] = useState([]);
  const [bdata, setBdata] = useState({});
  const [hdata, setHdata] = useState({});
  const [loader, setLoader] = useState(true);
  const [showButtons, setShowButtons] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(
        `/api/data/getPrint/${no}`
      );
      setBasicData(response.data.basic);
      setPrintRows(response.data.datarows[0]);
      setBdata(response.data.bdata);
      setHdata(response.data.hdata);
      setLoader(false);
      console.log(response.data)
    } catch (error) {
      setLoader(false);
    }
  };

  // const handleShare = async () => {
  //   setShowButtons(false);
  //   try {
  //     const options = {
  //       margin: 10,
  //       filename: "document.pdf",
  //       image: { type: "jpeg", quality: 0.98 },
  //       html2canvas: { scale: 4, width: document.body.scrollWidth },
  //       jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  //     };

  //     // Convert the content to PDF
  //     const pdf = await html2pdf()
  //       .from(contentRef.current)
  //       .set(options)
  //       .outputPdf("blob");

  //     // Create a File object from the PDF blob
  //     const pdfFile = new File([pdf], `RQST-${basicData.eno}.pdf`, {
  //       type: "application/pdf",
  //     });

  //     // Share the PDF using the Web Share API
  //     if (navigator.share) {
  //       await navigator.share({
  //         files: [pdfFile],
  //         title: "PDF Share",
  //         text: "Check out this PDF!",
  //       });
  //     } else {
  //       throw new Error("Web Share API is not supported in this browser.");
  //     }
  //     setShowButtons(true);
  //   } catch (error) {
  //     console.error("Error sharing PDF:", error);
  //   }
  // };

  const handleDownloadPDF = () => {
    if (contentRef.current) {
      const printableContent = contentRef.current;
      html2pdf()
        .set({ margin: 1, filename: `RQST-${basicData.eno}.pdf`, image: { type: 'jpeg', quality: 0.98 } })
        .from(printableContent)
        .output('datauristring') // Output as data URI string
        .then(function (pdfDataUri) {
          // Open PDF in new tab
          const pdfWindow = window.open();
          pdfWindow.document.write("<iframe width='100%' height='100%' src='" + pdfDataUri + "'></iframe>");
        });
    }
  };

  // const handleDownloadPDF = () => {
  //   if (contentRef.current) {
  //     const printableContent = contentRef.current;
  //     html2pdf()
  //       .set({ margin: 1, filename: `RQST-${basicData.eno}.pdf`, image: { type: 'jpeg', quality: 0.98 } })
  //       .from(printableContent)
  //       .save();
  //   }
  // };


 

  const handleShare = async () => {
    setShowButtons(false);
    try {
      const options = {
        margin: 0, // Set margin to zero
        filename: "document.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          scale: 2, // Initial scale factor
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }, // Set page format to A4
      };
  
      // Convert the content to PDF
      const pdf = await html2pdf()
        .from(contentRef.current)
        .set(options)
        .outputPdf("blob");
  
      // Create a File object from the PDF blob
      const pdfFile = new File([pdf], `RQST-${basicData.eno}.pdf`, {
        type: "application/pdf",
      });
  
      // Share the PDF using the Web Share API
      if (navigator.share) {
        await navigator.share({
          files: [pdfFile],
          title: "PDF Share",
          text: "Check out this PDF!",
        });
      } else {
        throw new Error("Web Share API is not supported in this browser.");
      }
      setShowButtons(true);
    } catch (error) {
      console.error("Error sharing PDF:", error);
    }
  };
  
  
  const styles = {
    borderBottom: "none",
    borderTop: "none",
    padding: "20px",
  };

  useEffect(() => {
    fetchData();
  }, []);

  let rows = [];

  for (let i = 0; i < 10 - printRows.length; i++) {
    rows.push(
      <tr key={`empty_${i}`}>
        <td style={styles}></td>
        <td style={styles}></td>
        <td style={styles}></td>
        <td style={styles}></td>
        <td style={styles}></td>
      </tr>
    );
  }

  return (
    <>
      {!loader ? (
        <div ref={contentRef} className="print-container">
          <div className="p-head">
            <div className="logos-container">
              <img className="logos" src="logo.jpg" alt="" />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="phead1">{basicData.loginname}</div>
              <div className="phead2">Order Request Form</div>
            </div>
            <div className="logos-container">
              <img className="logos" src="mz-logo.png" alt="" />
            </div>
          </div>
          <div className="m-data">
            {showButtons && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <button
                  style={{
                    width: "auto",
                    height: "auto",
                    margin: "3px 0 0 0",
                    padding: "8px",
                    fontSize: "14px",
                    backgroundColor: "white",
                    borderColor: "black",
                  }}
                  onClick={() => {
                    setShowButtons(false); // Hide the button before printing
                    setTimeout(() => {
                      handleDownloadPDF()
                      setShowButtons(true); // Show the button after printing
                    }, 100);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    width="20px"
                    viewBox="0 0 512 512"
                  >
                    <path d="M128 0C92.7 0 64 28.7 64 64v96h64V64H354.7L384 93.3V160h64V93.3c0-17-6.7-33.3-18.7-45.3L400 18.7C388 6.7 371.7 0 354.7 0H128zM384 352v32 64H128V384 368 352H384zm64 32h32c17.7 0 32-14.3 32-32V256c0-35.3-28.7-64-64-64H64c-35.3 0-64 28.7-64 64v96c0 17.7 14.3 32 32 32H64v64c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V384zM432 248a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                  </svg>
                </button>
                <button
                  style={{
                    width: "auto",
                    height: "auto",
                    margin: "3px 0 0 2px",
                    padding: "8px",
                    fontSize: "14px",
                    backgroundColor: "white",
                    borderColor: "black",
                  }}
                  onClick={handleShare}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    width="20px"
                    viewBox="0 0 448 512"
                  >
                    <path d="M352 224c53 0 96-43 96-96s-43-96-96-96s-96 43-96 96c0 4 .2 8 .7 11.9l-94.1 47C145.4 170.2 121.9 160 96 160c-53 0-96 43-96 96s43 96 96 96c25.9 0 49.4-10.2 66.6-26.9l94.1 47c-.5 3.9-.7 7.8-.7 11.9c0 53 43 96 96 96s96-43 96-96s-43-96-96-96c-25.9 0-49.4 10.2-66.6 26.9l-94.1-47c.5-3.9 .7-7.8 .7-11.9s-.2-8-.7-11.9l94.1-47C302.6 213.8 326.1 224 352 224z" />
                  </svg>
                </button>
              </div>
            )}
            <div className="m-data1">
              <div className="md">
                <p
                  style={{
                    borderBottom: "2px solid black",
                    textAlign: "center",
                  }}
                  className="blue-head"
                >
                  Bill To
                </p>
                <div style={{ padding: "4px" }}>
                  <p>
                    Party : <strong>{basicData.buyer}</strong>
                  </p>
                  <p>{bdata.address}</p>
                  {/* <p>{bdata.address.split(",")[1]}</p>
            <p>{bdata.address.split(",")[2]}</p> */}
                  <p>GSTIN: {bdata.gstno}</p>
                </div>
              </div>
              <div
                style={{ marginLeft: "5px", marginRight: "5px" }}
                className="md"
              >
                <p
                  style={{
                    borderBottom: "2px solid black",
                    textAlign: "center",
                  }}
                  className="blue-head"
                >
                  Ship To
                </p>
                <div style={{ padding: "4px" }}>
                  <p>
                    Party : <strong>{basicData.haste}</strong>
                  </p>
                  <p>{hdata.address}</p>
                  {/* <p>{hdata.address.split(",")[1]}</p>
            <p>{hdata.address.split(",")[2]}</p> */}
                  <p>GSTIN: {hdata.gstno}</p>
                </div>
              </div>
              <div>
                <table id="t1">
                  <tbody>
                    <tr>
                      <td className="blue-head">Request No.</td>
                      <td style={{ fontSize: "16px" }} className="left">
                        <strong>{basicData.eno}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-head">Date</td>
                      <td className="left">
                        <strong>{basicData.date.slice(0, 10)}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-head">Delivery Date</td>
                      <td className="left">
                        <strong>{basicData.deldate && basicData.deldate.slice(0, 10)}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-head">Agent</td>
                      <td className="left">
                        <strong>{bdata.broker}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td className="blue-head">Transport</td>
                      <td className="left">
                        <strong>{basicData.tport ||  bdata.tport}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="print-table-container">
            <table id="printtable">
              <thead>
                {/* <th>BaleNo</th> */}
                <th>SortNo</th>
                <th>TradeName</th>
                <th>Shade</th>                
                <th>Qty</th>
                <th>Remark</th>                
              </thead>
              <tbody>
                {/* <tr>
                    <td>12314214013</td>
                    <td>403242</td>
                    <td>NAMO BLue FIre</td>
                    <td>as per cutting</td>
                    <td>123</td>
                    <td>123</td>
                    <td>DB</td>
                </tr>
                <tr>
                    <td>1231421401334</td>
                    <td>40324224</td>
                    <td>NAMO BLue FIre red bl</td>
                    <td>as per cutting as</td>
                    <td>1233</td>
                    <td>12353</td>
                    <td>DB</td>
                </tr> */}
                {printRows.map((row) => (
                  <tr key={row.lumpno}>
                    {/* <td>{row.lumpno.startsWith('x-') ? '-' : row.lumpno}</td> */}
                    <td>{row.item}</td>
                    <td>{row.design}</td>
                    <td>{row.shade}</td>                    
                    <td>{row.stockqty}</td> 
                    <td>{row.remark}</td>                
                  </tr>
                ))}
                {rows}
                <tr className="blue-head">
                  <td>Total : {basicData.bales}</td>
                  <td></td>
                  <td></td>              
                  <td>{basicData.qty}</td>  
                  <td></td>                               
                </tr>
              </tbody>
            </table>
          </div>
          <div className="print-remark">
            <strong>Remark:</strong>{" "}
            {basicData.remark != "" ? basicData.remark : ""}
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default PrintForm;
