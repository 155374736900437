import React, { useState, useEffect, useRef } from "react";
import { FilterBox } from "./Dashboard";
import { CircularProgress } from "@mui/material";
import "./dashboard.css";
import axios from "axios";
import Select, { components } from "react-select";
import html2pdf from "html2pdf.js";
import * as XLSX from "xlsx";
import axiosInstance from "../api/axios";

const Soreg = ({ accounts, haste, serverurl, usertype, blist }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [showTable, setShowTable] = useState(true);
  const [enolist, setEnolist] = useState([]);
  const [designlist, setDesignlist] = useState([]);
  const [design, setDesign] = useState("");
  const [shadelist, setShadelist] = useState([]);
  const [shade, setShade] = useState("");
  const [days, setDays] = useState("");
  const [buy, setBuy] = useState("");
  const [broker, setBroker] = useState("");
  const [hasteacc, setHaste] = useState("");
  const [showFbtn, setShowFbtn] = useState(true);
  const [requests, setRequests] = useState([]);
  const [sdesign, setSdesign] = useState("");
  const selectref = useRef(null);
  const [filteredData, setFilteredData] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "date",
    direction: "desc",
  });

  const contentRef = useRef(null);

  const handleDownloadPDF = () => {
    if (contentRef.current) {
      const printableContent = contentRef.current;
      const width = printableContent.scrollWidth; // Get the width of the table
      const additionalSpace = window.innerWidth < 900 ? 200 : 0; // Check device width
      console.log(additionalSpace);

      html2pdf()
        .set({
          margin: 1,
          filename: `My SO.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2, width: width + additionalSpace }, // Set width + additional space
          jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
        })
        .from(printableContent)
        .save();
    }
  };

  const exportToExcel = () => {
    // Get the table element
    const table = document.getElementById("bookingTable");

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Create a new worksheet
    const worksheet = XLSX.utils.aoa_to_sheet([]);

    // Get the number of rows in the table
    const rowCount = table.rows.length;

    // Loop through each row in the table
    for (let i = 0; i < rowCount; i++) {
      const row = table.rows[i];
      const rowData = [];

      // Get the number of cells in the row
      const cellCount = row.cells.length;

      // Loop through each cell in the row
      for (let j = 0; j < cellCount; j++) {
        const cell = row.cells[j];

        // Check if this is the column where you want each line to appear on a new line
        if (cell.classList.contains("remark-lr")) {
          // Extract text from each <pre> element and add it to the cell content with line breaks
          const preElements = cell.getElementsByTagName("pre");
          let cellContent = "";
          for (let k = 0; k < preElements.length; k++) {
            cellContent += preElements[k].textContent.trim();
            if (k < preElements.length - 1) {
              cellContent += "\r\n"; // Add line break if not the last line
            }
          }
          rowData.push(cellContent);
        } else {
          // For other columns, add the cell's text directly
          rowData.push(cell.textContent);
        }
      }

      // Add the row data to the worksheet
      XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: -1 });
    }

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, `My Bookings.xlsx`);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.keyCode === 13) {
        const button = document.getElementById("search");
        if (button) {
          button.click();
        }
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const sortTable = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = [...filteredData].sort((a, b) => {
    const keyA = a[sortConfig.key];
    const keyB = b[sortConfig.key];
    // Custom comparison for date column
    if (sortConfig.key === "date") {
      const dateA = new Date(keyA.split("-").reverse().join("-"));
      const dateB = new Date(keyB.split("-").reverse().join("-"));
      return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
    }
    // Default comparison for other columns
    return sortConfig.direction === "asc"
      ? keyA.localeCompare(keyB)
      : keyB.localeCompare(keyA);
  });

  const buyerOptions = accounts.map((b) => ({ value: b, label: b }));
  const hasteOptions = haste.map((h) => ({ value: h, label: h }));
  const designOptions = designlist.map((b) => ({ value: b, label: b }));
  const shadeOptions = shadelist.map((h) => ({ value: h, label: h }));
  const brokerOptions = blist.map((h) => ({ value: h, label: h }));

  const fetchRequests = async () => {
    try {
      setIsLoading(true);
      setIsLoading2(true);
      const response = await axiosInstance.post(`/api/data/soreq`, {
        buyerNames: accounts.map((item) => item.trim()),
      });
      setRequests(response.data.data);
      setFilteredData(response.data.data);
      setIsLoading2(false);
      setIsLoading(false);
      setShowTable(true);
      setTimeout(() => {
        const footerElement = document.getElementById("footer");
        const tableContainerElement =
          document.getElementById("table-container");

        if (footerElement && tableContainerElement) {
          const footerOffset = footerElement.offsetTop;
          const tableContainerOffset = tableContainerElement.offsetTop;
          const calculatedHeight = footerOffset - tableContainerOffset - 2;
          tableContainerElement.style.height = `${calculatedHeight}px`;
        }
      }, 250);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const footerElement = document.getElementById("footer");
      const tableContainerElement = document.getElementById("table-container");

      if (footerElement && tableContainerElement) {
        const footerOffset = footerElement.offsetTop;
        const tableContainerOffset = tableContainerElement.offsetTop;
        const calculatedHeight = footerOffset - tableContainerOffset - 2;
        tableContainerElement.style.height = `${calculatedHeight}px`;
      }
    }, 250);
  }, [showTable, showFilters]);

  // const handleFilter = () => {
  //   setShowFilters(false);
  //   setShowFbtn(true);
  //   const filtered = requests.filter((item) => {

  //     if (
  //       item.buyer.includes(buy) &&
  //       item.design.includes(design) &&
  //       item.haste.includes(haste) &&
  //       item.shade.includes(shade) &&
  //       item.days >= days
  //     ) {
  //       return true;
  //     }
  //   });
  //   console.log(filtered);
  //   setFilteredData(filtered);
  //   setShowTable(true);
  // };

  const handleFilter = () => {
    setShowFilters(false);
    setShowFbtn(true);
    setSdesign("")

    const filtered = requests.filter((item) => {
      // Assuming buy, design, haste, shade, and days are state variables
      const buyMatch = item.buyer.includes(buy?.trim());
      const designMatch = item.design.includes(design);
      const hasteMatch = item.haste.includes(hasteacc);
      const shadeMatch = item.shade.includes(shade);
      const brokerMatch = item.broker.includes(broker?.trim());
      const daysMatch = item.days >= days;

      console.log(buyMatch, designMatch, hasteMatch, shadeMatch);
      // Ensure that all conditions match
      return buyMatch && designMatch && hasteMatch && shadeMatch && daysMatch && brokerMatch;
    });

    console.log(filtered);

    setFilteredData(filtered);
    setShowTable(true);
  };


  useEffect(()=> {
    const handleFilter1 = () => {
      setShowFilters(false);
      setShowFbtn(true);
  
      const filtered = requests.filter((item) => {
        // Assuming buy, design, haste, shade, and days are state variables       
        const buyMatch = usertype != 'Customer' ? (sdesign == "" ? item.buyer.includes(buy?.trim()) : item.buyer.includes(sdesign?.trim())) : item.buyer.includes(buy?.trim());
        const designMatch = usertype == 'Customer' ? (sdesign == "" ? item.design.includes(design?.trim()) : item.design.includes(sdesign?.trim())) : item.buyer.includes(design);
        const hasteMatch = item.haste.includes(hasteacc);
        const shadeMatch = item.shade.includes(shade);
        const daysMatch = item.days >= days;
        const brokerMatch = item.broker.includes(broker?.trim())
  
        //console.log(buyMatch, designMatch, hasteMatch, shadeMatch);
        // Ensure that all conditions match
        return buyMatch && designMatch && hasteMatch && shadeMatch && daysMatch && brokerMatch;
      });
  
      console.log(filtered);
  
      setFilteredData(filtered);
      setShowTable(true);
    };
    handleFilter1()
  },[sdesign])

  const CustomControl = (props) => (
    <components.Control {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        height={"20px"}
        width={"20px"}
      >
        <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
      </svg>
      {props.children}
    </components.Control>
  );

  const soptions = usertype == "Customer" ? designOptions : buyerOptions;

  const getFilters = async () => {
    try {
      const response = await axiosInstance.get(`/api/data/getsof`);
      setDesignlist(response.data.design);
      setShadelist(response.data.shade);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFilters();
    fetchRequests();
  }, []);

  return (
    <div className="container">
      {!isLoading && (
        <>
          {showFbtn && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                marginBottom: '10px'
              }}
            >
              {!(design || buy || shade || hasteacc || days || broker) && (
                <div
                  className="filter-item cfi"
                  style={{ marginBottom: "0px", flex: "1" }}
                >
                  <Select
                    ref={selectref}
                    value={sdesign ? { value: sdesign, label: sdesign } : null}
                    options={soptions.sort((a, b) =>
                      a.label.toString().localeCompare(b.label.toString())
                    )}
                    placeholder={"Search"}
                    isClearable={true}
                    components={{ Control: CustomControl }}
                    onChange={(option) => {
                      setSdesign(option == null ? "" : option.label);
                      if (option == null && selectref.current) {
                        selectref.current.blur();
                      }
                    }}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "100%",
                        paddingInlineStart: "10px",
                        borderRadius: "25px",
                        backgroundColor: "lightgrey",
                        textAlign: "left",
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        maxHeight: "200px",
                        textAlign: "left",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 6,
                      }),
                      container: (provided) => ({
                        ...provided,
                        width: "90%",
                      }),
                    }}
                  />
                </div>
              )}
              <button
                className="filter-btn"
                onClick={() => {
                  if(design || buy || shade || hasteacc || days || broker){
                    setDays("");
                    setBuy("");
                    setDesign("");
                    setShade("");
                    setHaste("");
                    setBroker("")
                    fetchRequests();
                  }else{
                    setShowFilters(!showFilters);
                    setShowFbtn(false);
                  }
                  
                }}
              >
                {design || buy || shade || hasteacc || days || broker ? "Clear" : "More"} Filters
              </button>
              <p
                style={{ marginLeft: "5px", color: "red", fontStyle: "italic" }}
              >
                {design || buy || shade || hasteacc || days || broker ? (
                  <>
                    Showing Results for: {buy && `"${buy}"`}{" "}
                    {shade && `"${shade}"`} {design && `"${design}"`}{" "}
                    {hasteacc && `"${hasteacc}"`} {days && `"${days}"`}{" "}
                    {broker && `"${broker}"`}{" "}
                  </>
                ) : (
                  <></>
                )}
              </p>
            </div>
          )}
          {showFilters && (
            <div className="filter-boxes">
              <div className="filter-item">
                <p
                  onClick={() => {
                    setShowFbtn(true);
                    setShowFilters(false);
                  }}
                  style={{
                    cursor: "pointer",
                    border: "2px solid #0B2B43",
                    borderRadius: "50%",
                    padding: "5px 10px 5px 10px",
                    color: "white",
                    backgroundColor: "#0B2B43",
                  }}
                >
                  X
                </p>
              </div>
              <div className="filter-item ">
                <Select
                  value={buy ? { value: buy, label: buy } : null}
                  isClearable={true}
                  onChange={(option) => {
                    setBuy(option == null ? "" : option.label);
                    setShowTable(false);
                  }}
                  options={buyerOptions.sort((a, b) =>
                    a.label.localeCompare(b.label)
                  )}
                  placeholder="Buyer"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: "200px", // Adjust width as needed
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: "200px", // Adjust height as needed
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 6,
                    }),
                  }}
                />
              </div>
              <div className="filter-item ">
                <Select
                  value={broker ? { value: broker, label: broker } : null}
                  isClearable={true}
                  onChange={(option) => {
                    setBroker(option == null ? "" : option.label);
                    setShowTable(false);
                  }}
                  options={brokerOptions.sort((a, b) =>
                    a.label.localeCompare(b.label)
                  )}
                  placeholder="Broker"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: "200px", // Adjust width as needed
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: "200px", // Adjust height as needed
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 6,
                    }),
                  }}
                />
              </div>
              <div className="filter-item">
                <Select
                  value={hasteacc ? { value: hasteacc, label: hasteacc } : null}
                  isClearable={true}
                  onChange={(option) => {
                    setHaste(option == null ? "" : option.label);
                    setShowTable(false);
                  }}
                  options={hasteOptions.sort((a, b) =>
                    a.label.localeCompare(b.label)
                  )}
                  placeholder="Haste"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: "200px", // Adjust width as needed
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: "200px", // Adjust height as needed
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 6,
                    }),
                  }}
                />
              </div>
              <div className="filter-item">
                <Select
                  value={design ? { value: design, label: design } : null}
                  isClearable={true}
                  onChange={(option) => {
                    setDesign(option == null ? "" : option.label);
                    setShowTable(false);
                  }}
                  options={designOptions.sort((a, b) =>
                    a.label.localeCompare(b.label)
                  )}
                  placeholder="Design"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: "200px", // Adjust width as needed
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: "200px", // Adjust height as needed
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 6,
                    }),
                  }}
                />
              </div>
              <div className="filter-item">
                <Select
                  value={shade ? { value: shade, label: shade } : null}
                  isClearable={true}
                  onChange={(option) => {
                    setShade(option == null ? "" : option.label);
                    setShowTable(false);
                  }}
                  options={shadeOptions.sort((a, b) =>
                    a.label.localeCompare(b.label)
                  )}
                  placeholder="Shade"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: "200px", // Adjust width as needed
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: "200px", // Adjust height as needed
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 6,
                    }),
                  }}
                />
              </div>
              <div className="filter-item">
                <input
                  className="filterip "
                  type="text"
                  placeholder="Days"
                  value={days}
                  onFocus={(event) => event.target.select()}
                  onChange={(e) => {
                    setDays(e.target.value);
                    setShowTable(false);
                  }}
                  style={{
                    width: "100px",
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                />
              </div>
              <div className="filter-item filter-item2">
                <button
                  id="search"
                  onClick={handleFilter}
                  style={{
                    width: "80px",
                    margin: 0,
                    fontSize: "12px",
                    padding: "10px",
                    height: "auto",
                  }}
                >
                  Search
                </button>
                <button
                  onClick={() => {
                    setDays("");
                    setBuy("");
                    setDesign("");
                    setShade("");
                    setHaste("");
                    setBroker("")
                    fetchRequests();
                  }}
                  style={{
                    width: "80px",
                    margin: 0,
                    fontSize: "12px",
                    padding: "10px",
                    height: "auto",
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
          )}
        </>
      )}
      {isLoading2 ? (
        <CircularProgress />
      ) : requests.length > 0 ? (
        <div
          style={{ backgroundColor: "white", padding: 10 }}
          className="table-container"
          id="table-container"
        >
          {showTable ? (
            <table
              id="bookingTable"
              className="responsive-table"
              ref={contentRef}
            >
              <thead>
                <tr className="sticky3">
                  <th className="sticky">Eno</th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => sortTable("date")}
                  >
                    Date
                    {sortConfig.key === "date" &&
                      (sortConfig.direction === "asc" ? "▲" : "▼")}
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      sortTable("buyer");
                      console.log(sortedData);
                    }}
                  >
                    Buyer
                    {sortConfig.key === "buyer" &&
                      (sortConfig.direction === "asc" ? "▲" : "▼")}
                  </th>
                  <th>Broker</th>
                  <th>Design</th>
                  <th>Shd</th>
                  <th>Qty</th>
                  <th>Days</th>
                  <th>Haste</th>
                </tr>
              </thead>
              <tbody>
                {sortedData.map((row, index) => (
                  <tr key={index}>
                    <td
                      className="sticky"
                      style={{
                        cursor: "pointer",
                        fontWeight: 500,
                        textDecoration: "underline",
                        textDecorationColor: "#0B2B43",
                      }}
                      //onClick={() => handleLink(row.eno)}
                    >
                      <strong>{row.eno}</strong>
                    </td>
                    <td>{row.date.split("-").join("/")}</td>
                    <td>{row.buyer}</td>
                    <td>{row.broker}</td>
                    <td>{row.design}</td>
                    <td>{row.shade}</td>
                    <td>{row.qty}</td>
                    <td>{row.days}</td>
                    <td>{row.haste}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}
        </div>
      ) : (
        <div>Zero records found.</div>
      )}
      <div id="footer" className="footer">
        <div className="total-details">
          <ul>
            <li>Total: {filteredData.length}</li>
            <li>
              Total Qty:{" "}
              {filteredData.reduce((acc, obj) => acc + parseFloat(obj.qty), 0)}
            </li>
          </ul>
        </div>
        {sortedData.length > 0 && (
          <div>
            <button
              className="footbut"
              onClick={exportToExcel}
              style={{
                width: "80px",
                margin: 0,
                fontSize: "12px",
                padding: "10px",
                height: "auto",
              }}
            >
              Excel
            </button>
            <button
              className="footbut"
              onClick={handleDownloadPDF}
              style={{
                width: "80px",
                margin: 0,
                fontSize: "12px",
                padding: "10px",
                height: "auto",
              }}
            >
              Download
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Soreg;
