import React, { useState, useEffect } from "react";
import axiosInstance from "../api/axios";
import Select from "react-select";
import { CircularProgress } from "@mui/material";
import * as XLSX from "xlsx";

const Logbook = ({ accounts }) => {
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [buy, setBuy] = useState("");
  const [design, setDesign] = useState("");
  const [moduleS, setModuleS] = useState("");
  const [filter, setFIlter] = useState([]);
  const [module, setModule] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showTable, setShowTable] = useState(false);

  function removeDuplicates(arr) {
    arr.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
    const result = arr.reduce((acc, current) => {
      const last = acc[acc.length - 1];
      if (
        last &&
        last.user === current.user &&
        last.stock === current.stock &&
        last.filter === current.filter &&
        (new Date(current.timestamp) - new Date(last.timestamp)) <= 7000 
      ) {        
        acc[acc.length - 1] = current;
      } else {
        acc.push(current);
      }
      return acc;
    }, []);
    result.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    return result;
  }

  useEffect(() => {
    setTimeout(() => {
      const footerElement = document.getElementById("footer");
      const tableContainerElement = document.getElementById("table-container");

      if (footerElement && tableContainerElement) {
        const footerOffset = footerElement.offsetTop;
        const tableContainerOffset = tableContainerElement.offsetTop;
        const calculatedHeight = footerOffset - tableContainerOffset - 2;
        tableContainerElement.style.height = `${calculatedHeight}px`;
      }
    }, 250);
  }, [filteredLogs]);

  const buyerOptions = accounts.map((b) => ({ value: b, label: b }));

  const fetchLogs = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post("api/data/getlogs", {
        buyerNames: accounts.map((item) => item.trim()),
      });
      setLogs(removeDuplicates(response.data.data));
      setFilteredLogs(removeDuplicates(response.data.data))
      const filterArray = response.data.data
        ?.map((item) => item.filter)
        .join(",")
        .split(",");
      const filters = new Set(filterArray);
      const moduleArray = response.data.data?.map(item => item?.module);
      const modules = new Set(moduleArray);
      setModule(modules);
      setFIlter(filters);
      setShowTable(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchClick = () => {
    try {     
      const filtered = logs.filter((item) => {      
        const buyMatch = item.loginname.includes(buy?.trim());
        const designMatch = item.filter.includes(design);
        const moduleMatch = item.module.includes(moduleS?.trim())   
        return buyMatch && designMatch && moduleMatch
      });      
      setFilteredLogs(filtered);
      setShowTable(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  const exportToExcel = () => {
    // Get the table element
    const table = document.getElementById("bookingTable");    
    const workbook = XLSX.utils.book_new();    
    const worksheet = XLSX.utils.aoa_to_sheet([]);    
    const rowCount = table.rows.length;    
    for (let i = 0; i < rowCount; i++) {
      const row = table.rows[i];
      const rowData = [];      
      const cellCount = row.cells.length;
      for (let j = 0; j < cellCount; j++) {
        const cell = row.cells[j];
        rowData.push(cell.textContent);
      }
      
      XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: -1 });
    }    
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");    
    XLSX.writeFile(workbook, `My Logs.xlsx`);
  };
  return (
    <div className="container">
      {loading ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="filter-boxes">
            <div className="filter-item">
              <Select
                value={buy ? { value: buy, label: buy } : null}
                isClearable={true}
                onChange={(option) => {
                  setShowTable(false);
                  setBuy(option == null ? "" : option.label);
                }}
                options={buyerOptions.sort((a, b) =>
                  a.label.localeCompare(b.label)
                )}
                placeholder="User"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: "200px",
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: "200px",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 6,
                  }),
                }}
              />
            </div>
            <div className="filter-item">
              <Select
                value={moduleS ? { value: moduleS, label: moduleS } : null}
                isClearable={true}
                onChange={(option) => {
                  setShowTable(false);
                  setModuleS(option == null ? "" : option.label);
                }}
                options={[...module]
                  .map((item) => ({ value: item, label: item }))
                  .sort((a, b) => a?.label?.localeCompare(b.label))}
                placeholder="Module"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: "200px",
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: "200px",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 6,
                  }),
                }}
              />
            </div>
            <div className="filter-item">
              <Select
                value={design ? { value: design, label: design } : null}
                isClearable={true}
                onChange={(option) => {
                  setShowTable(false);
                  setDesign(option == null ? "" : option.label);
                }}
                options={[...filter]
                  .map((item) => ({ value: item, label: item }))
                  .sort((a, b) => a?.label?.localeCompare(b.label))}
                placeholder="Filter"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: "200px",
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: "200px",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 6,
                  }),
                }}
              />
            </div>
            <div className="filter-item">
              <button
                id="search"
                onClick={() => {
                  handleSearchClick();
                }}
                style={{
                  width: "80px",
                  margin: 0,
                  fontSize: "12px",
                  padding: "10px",
                  height: "auto",
                }}
              >
                Search
              </button>
            </div>
          </div>
          {showTable ? (
            <div
              style={{
                backgroundColor: "white",
              }}
              className="table-container"
              id="table-container"
            >
              <table id="bookingTable" className="responsive-table">
                <thead>
                  <tr className="sticky3">
                    <th>Time</th>
                    <th>User</th>
                    <th>Module</th>
                    <th>Filter</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredLogs.map((row) => (
                    <tr key={row.recid}>
                      <td>{row.timestamp}</td>
                      <td>{row.loginname}</td>
                      <td>{row.module}</td>
                      <td>{row.filter}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
          <div id="footer" className="footer">
          <button
              className="footbut"
              onClick={exportToExcel}
              style={{
                width: "80px",
                margin: 0,
                fontSize: "12px",
                padding: "10px",
                height: "auto",
              }}
            >
              Excel
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Logbook;
