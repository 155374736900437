import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import { CircularProgress } from "@mui/material";
import * as XLSX from "xlsx";
import "./dashboard.css";
import PrintForm from "./PrintForm";
import SaleReg from "./SaleReg";
import MyOut from "./MyOut";
import Catalogue from "./Catalogue";
import Soreg from "./Soreg";
import Osentry from "./Osentry";
import Select from "react-select";
import MyProfile from "./MyProfile";
import html2pdf from "html2pdf.js";
import axiosInstance from '../api/axios';
import Logbook from "./Logbook";

const Dashboard = () => {
  const [serverurl, setServerurl] = useState("http://localhost:3001");
  const [username, setUsername] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [usertype, setUsertype] = useState("")
  const [tradeName, setTradeName] = useState([]);
  const [sortno, setSortno] = useState([]);
  const [shadeno, setShade] = useState([]);
  const [ptype, setPtype] = useState([]);
  const [avgcut, setAvgc] = useState([]);
  const [brlist, setBrlist] = useState([])
  const [avgcuts, setAvgcs] = useState("");
  const [avgcute, setAvgce] = useState("");
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(true);
  const [refreshKey, setRefreshKey] = useState(0);
  const [tn, setTn] = useState("");
  const [sn, setSn] = useState("");
  const [shn, setShn] = useState("");
  const [pn, setpn] = useState("");
  const [tdata, setTdata] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [acounts, setAccounts] = useState([]);
  const [haste, setHaste] = useState([]);
  const [tportop, setTportop] = useState([]);
  const [bookAcc, setBookAcc] = useState("");
  const [hasteAcc, setHasteAcc] = useState("");
  const [tportAcc, setTportAcc] = useState("");
  const [hoveredRowInfo, setHoveredRowInfo] = useState(null);
  const [totalCheckboxChecked, setTotalCheckboxChecked] = useState(0);
  const [totalPcs, setTotalPcs] = useState(0);
  const [totalQty, setTotalQty] = useState(0.0);
  const [doneVerified, setDoneVerified] = useState(false);
  const [ddate, setDdate] = useState("");
  const [remark, setRemark] = useState("");
  const [showTable, setShowTable] = useState(true);
  const [clickedEno, setClickedEno] = useState(null);
  const [showModalP, setShowModalP] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showFbtn, setShowFbtn] = useState(true);
  const [requestStatus, setRequestStatus] = useState({
    success: false,
    message: "",
  });
  const [showContent, setShowContent] = useState(true);
  const [modalLoader, setModalLoader] = useState(false);
  const [showAlert, setShowAlert] = useState({
    status: false,
    message: "",
  });
  const [activeTab, setActiveTab] = useState("myprofile");
  const [activeTab2, setActiveTab2] = useState("cat");
  const [isCartOpen, setIsCartOpen] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const handleTabClick2 = (tab) => {
    setActiveTab2(tab);
  };

  const history = useNavigate();

  const dashboardValid = async () => {
    try {
      const token = localStorage.getItem("logintoken");
      const response = await axiosInstance.get(`/api/auth/dashboard`);
      setUsername(response.data.user.username);
      setName(response.data.user.name.trim());
      setUsertype(response.data.user.usertype)      
      const response3 = await axiosInstance.get(
        `/api/data/ogpacc/${response.data.user.name.trim()}`
      );
      setDoneVerified(true);
      setBrlist(response3.data.brokerlist)
      setAccounts(response3.data.accountslist);
      setHaste(response3.data.hlist);
    } catch (error) {
      history("/login");
    }
  };

  const toggleCart = () => {
    setIsCartOpen((prevIsCartOpen) => !prevIsCartOpen);
  };

  const dashboardData = async () => {
    try {
      setLoader(false);
      //setSelectedRows([]);
      //const response = await axiosInstance.get("/api/data/filter");
      const [response] = await Promise.all([
        axiosInstance.get(`/api/data/filter`),
        // axiosInstance.get(`/api/data/table/x/x/x/x/x/x`),
      ]);
      setTradeName(response.data.tradename);      
      setSortno(response.data.sortno);
      setShade(response.data.shadeno);
      setPtype(response.data.ptn);
      setAvgc(response.data.acl);
      setTportop(response.data.tp);
      // const response2 = await axiosInstance.get(
      //   "/api/data/table/x/x/x/x/x/x"
      // );
      //setTdata(response2.data.data);
      setShowTable(true);
      setLoader(true);
      setTimeout(() => {
        const footerElement = document.getElementById("footer");
        const tableContainerElement =
          document.getElementById("table-container");

        if (footerElement && tableContainerElement) {
          const footerOffset = footerElement.offsetTop;
          const tableContainerOffset = tableContainerElement.offsetTop;
          const calculatedHeight = footerOffset - tableContainerOffset - 2;
          tableContainerElement.style.height = `${calculatedHeight}px`;
        }
      }, 250);
    } catch (error) {
      console.log(error);
      setShowAlert({ status: true, message: error.message });
    }
  };

  const filteredDashData = async () => {
    //setSelectedRows([]);
    setShowFbtn(true);
    setShowFilters(false);
    try {
      setLoader2(false);
      const result = await axiosInstance.get(
        `/api/data/table/${tn == "" ? "x" : tn}/${
          sn == "" ? "x" : sn
        }/${encodeURIComponent(shn == "" ? "x" : shn)}/${pn == "" ? "x" : pn}/${
          avgcuts == "" ? "x" : avgcuts
        }/${avgcute == "" ? "x" : avgcute}`
      );
      setTdata(result.data.data);
      setLoader2(true);
      setIsCartOpen(false);
      setShowTable(true);
      setTimeout(() => {
        const footerElement = document.getElementById("footer");
        const tableContainerElement =
          document.getElementById("table-container");

        if (footerElement && tableContainerElement) {
          const footerOffset = footerElement.offsetTop;
          const tableContainerOffset = tableContainerElement.offsetTop;
          const calculatedHeight = footerOffset - tableContainerOffset - 2;
          tableContainerElement.style.height = `${calculatedHeight}px`;
        }
      }, 0);
    } catch (error) {
      console.log(error);
      setShowAlert({ status: true, message: error.message });
    }
  };

  const handleCheckboxChange = (event, row) => {
    if (event.target.checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, row]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter(
          (selectedRow) => selectedRow.lumpno !== row.lumpno
        )
      );
    }
  };

  const handleSendDataToDatabase = async () => {
    try {
      const totalPcs = selectedRows.reduce((acc, obj) => acc + obj.pcs, 0);
      const totalQty = selectedRows.reduce((acc, obj) => acc + obj.stockqty, 0);
      const totalCheckboxChecked = selectedRows.length;
      if (bookAcc == "") {
        return alert("Please select a buyer.");
      }
      setShowContent(false);
      setModalLoader(true);
      const data = {
        datarows: { ...selectedRows },
        totalPcs,
        totalQty,
        totalCheckboxChecked,
        name,
        bookAcc,
        hasteAcc,
        tportAcc,
        ddate,
        remark,
      };
      console.log(data);
      const response = await axiosInstance.post(
        `/api/data/insertData`,
        data
      );
      setModalLoader(false);
      setRequestStatus({ status: true, message: response.data.message });
      //filteredDashData();
      console.log("Selected rows:", selectedRows);
      setSelectedRows([]);
      setBookAcc("");
      setHasteAcc("");
      setTotalCheckboxChecked(0);
      setTotalPcs(0);
      setTotalQty(0);
      setDdate("");
      setRemark("");
      //setIsModalOpen(false);
    } catch (error) {
      console.log(error);
      setModalLoader(false);
      setIsModalOpen(false);
      setShowAlert({ status: true, message: error.response.data.message });
      //setRequestStatus({ status: false, message: error.response.data.message });
    }
  };

  const handleDateSelect = (date) => {
    console.log(date);
    setDdate(date); // Here you can use the selected date
  };

  const handleRemark = (remark) => {
    setRemark(remark);
  };

  const openModal = () => {
    console.log(selectedRows.length);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeModalok = () => {
    setIsModalOpen(false);
    setRequestStatus({ success: false, message: "" });
    setShowContent(true);
    filteredDashData();
  };

  const setHis = () => {
    setShowModalP(true);
  };

  const handleCloseModal = () => {
    setShowModalP(false);
  };

  const closeAlert = () => {
    setShowAlert({ status: false, message: "" });
    setShowContent(true);
    filteredDashData();
  };

  const handleLogout = () => {
    localStorage.removeItem("logintoken");
    history("/login");
  };

  useEffect(() => {
    setIsModalOpen(false);
    setRequestStatus({ success: false, message: "" });
    setShowContent(true);
    setTotalCheckboxChecked(0);
    setTotalPcs(0);
    setTotalQty(0);
    dashboardValid();
    dashboardData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const footerElement = document.getElementById("footer");
      const tableContainerElement = document.getElementById("table-container");

      if (footerElement && tableContainerElement) {
        const footerOffset = footerElement.offsetTop;
        const tableContainerOffset = tableContainerElement.offsetTop;
        const calculatedHeight = footerOffset - tableContainerOffset - 2;
        tableContainerElement.style.height = `${calculatedHeight}px`;
      }
    }, 250);
  }, [showTable, showFilters]);

  //   useEffect(() => {
  //     console.log("activeTab changed:", activeTab);
  //     // Call dashboardData function when activeTab changes to "myRequests"
  //     if (activeTab === "myRequests") {
  //         console.log("Calling dashboardData...");
  //         dashboardData();
  //     }
  // }, [activeTab]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.keyCode === 13) {
        const button = document.getElementById("search");
        if (button) {
          button.click();
        }
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div className="app2">
      {doneVerified ? (
        <>
          <div className="dashhead">
            <img style={{ width: "70px", marginLeft:'4px' }} src="logo.jpg" alt="" />
            <div className="center-name">Welcome, {name && name}</div>
            <button onClick={handleLogout} className="nav-buttons">
              Logout
            </button>
          </div>

          <div>
            <div className="tabs">
              <div
                className={activeTab === "myprofile" ? "tab active" : "tab"}
                onClick={() => handleTabClick("myprofile")}
              >
                My Profile
              </div>
              <div
                className={activeTab === "newRequests" ? "tab active" : "tab"}
                onClick={() => handleTabClick("newRequests")}
              >
                Stock & Order
              </div>
              <div
                className={activeTab === "saleReg" ? "tab active" : "tab"}
                onClick={() => handleTabClick("saleReg")}
              >
                Bill / LR
              </div>
              <div
                className={activeTab === "outs" ? "tab active" : "tab"}
                onClick={() => handleTabClick("outs")}
              >
                My O/S
              </div>
              <div
                className={activeTab === "soreg" ? "tab active" : "tab"}
                onClick={() => handleTabClick("soreg")}
              >
                Pending S/O
              </div>
              <div
                className={activeTab === "logs" ? "tab active" : "tab"}
                onClick={() => handleTabClick("logs")}
              >
                Logs
              </div>             
            </div>
            <div className="tab-content ">
              {activeTab === "newRequests" && (
                <>
                  <div className="ostabs nomargintop">
                    <div
                      className={
                        activeTab2 === "cat" ? "ostab active" : "ostab"
                      }
                      onClick={() => handleTabClick2("cat")}
                    >
                      Catalogue
                    </div>                    
                    <div
                      className={
                        activeTab2 === "mbook" ? "ostab active" : "ostab"
                      }
                      onClick={() => handleTabClick2("mbook")}
                    >
                      My Bookings
                    </div>
                    {/* Add more tabs as needed */}
                  </div>
                  <div className="ostab-content ">
                   {activeTab2 === "cat" && (
                      <>
                        <Catalogue
                          acounts={acounts}
                          haste={haste}
                          dlist={tradeName}
                          ilist={sortno}
                          loginname={name}
                          serverurl={serverurl}
                        />
                      </>
                    )}                     
                    {activeTab2 === "mbook" && (
                      <>
                        <MyRequests
                          loginname={name}
                          sortno={sortno}
                          shadeno={shadeno}
                          tradeName={tradeName}
                          ptype={ptype}
                          accounts={acounts}
                          serverurl={serverurl}
                        />
                      </>
                    )}
                  </div>
                </>
              )}
              {activeTab === "saleReg" && (
                <>
                  <SaleReg
                    accounts={acounts}
                    sortno={sortno}
                    shadeno={shadeno}
                    tradeName={tradeName}
                    ptype={ptype}
                    serverurl={serverurl}
                    usertype={usertype}
                    blist = {brlist}
                  />
                </>
              )}
              {activeTab === "outs" && (
                <>
                  <MyOut accounts={acounts} serverurl={serverurl} blist={brlist}/>
                </>
              )}
              {activeTab === "soreg" && (
                <>
                  <Soreg
                    accounts={acounts}
                    haste={haste}
                    serverurl={serverurl}
                    usertype={usertype}
                    blist = {brlist}
                  />
                </>
              )}
              {activeTab === "myprofile" && (
                <>
                  <MyProfile
                    loginname={name}
                    accounts={acounts}
                    serverurl={serverurl}
                  />
                </>
              )}
              {activeTab === "logs" && (
                <>
                  <Logbook
                  accounts={acounts}                  
                  />
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
};

const FilterBox = ({
  options,
  onOptionSelect,
  placeholder,
  moreStyles,
  isSpecial,
  isSpecial2,
  onInputBlur,
  defaultFilterValue,
}) => {
  const [filterValue, setFilterValue] = useState(
    defaultFilterValue == "x" ? "" : defaultFilterValue || ""
  );
  const [visible, setVisible] = useState(true);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const blurTimeoutRef = useRef(null);
  //const [isOptionsListFocused, setOptionsListFocused] = useState(false);

  const defaultStyles = {
    width: "150px",
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  };

  const handleInputFocus = (event) => {
    event.target.select();
    setIsInputFocused(true);
  };

  const mergedStyles = { ...defaultStyles, ...moreStyles };

  const filteredOptions = options.filter((option) => {
    if (typeof option === "number") {
      return option.toString().includes(filterValue.toString());
    } else if (typeof option === "string") {
      return option.toLowerCase().includes(filterValue.toLowerCase());
    }
  });

  const handleInputChange = (e) => {
    setVisible(true);
    setFilterValue(e.target.value);
    if (e.target.value == "") {
      onOptionSelect("x");
    }
  };

  const handleOptionClick = (option) => {
    clearTimeout(blurTimeoutRef.current);
    setVisible(false);
    onOptionSelect(option);
    setFilterValue(option);
  };
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const listRef = useRef(null);

  useEffect(() => {
    // Scroll to selected item when selectedIndex changes
    if (listRef.current && selectedIndex !== -1) {
      const selectedElement = listRef.current.childNodes[selectedIndex];
      selectedElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [selectedIndex]);

  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      // Move selection down
      setSelectedIndex((prevIndex) =>
        Math.min(prevIndex + 1, filteredOptions.length - 1)
      );
    } else if (event.key === "ArrowUp") {
      // Move selection up
      setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, -1));
    } else if (event.key === "Enter" && selectedIndex !== -1) {
      // Handle Enter key press
      handleOptionClick(filteredOptions[selectedIndex]);
    }
  };
  // const handleBlur = (event) => {
  //   // Reset filterValue if no option is selected
  //   if (selectedIndex === -1) {
  //     onInputBlur(); // Call parent component's function to reset filterValue
  //   }
  // };

  filteredOptions.sort();

  return (
    <div
      className="filter-container"
      style={{ display: "inline-block", position: "relative" }}
    >
      <input
        className={`filterip ${isSpecial ? "ft" : ""} ${
          isSpecial2 ? "ft2" : ""
        }`}
        type="text"
        id="filterinp"
        autoComplete="off"
        value={filterValue}
        onChange={handleInputChange}
        //onBlur={(event)=>{if(selectedIndex==-1 && !isOptionsListFocused){setFilterValue('')}}}
        onBlur={() => {
          blurTimeoutRef.current = setTimeout(() => {
            setIsInputFocused(false);
          }, 500);
        }}
        placeholder={placeholder}
        style={mergedStyles}
        onFocus={handleInputFocus}
        onKeyDown={handleKeyDown}
      />
      {isInputFocused && visible && (
        <ul
          ref={listRef}
          className="options-list"
          style={{
            listStyleType: "none",
            padding: "0",
            margin: "0",
            position: "absolute",
            top: "calc(100% + 5px)",
            left: "0",
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            zIndex: "4",
            overflowY: "scroll",
            height: 150,
            width: "200px",
          }}
        >
          {filteredOptions.map((option, index) => (
            <li
              key={index}
              onClick={() => handleOptionClick(option)}
              style={{
                padding: "8px 16px",
                cursor: "pointer",
                backgroundColor:
                  index === selectedIndex ? "#f0f0f0" : "transparent",
              }}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

function Modal({
  onClose,
  onPlaceOrder,
  data,
  data2,
  tportoptions,
  setFF,
  setTT,
  setF,
  totalCheckboxChecked,
  totalPcs,
  totalQty,
  setDate,
  setR,
  requestStatus,
  showContent,
  loader,
  onCloseok,
  showAlert,
  closeAlert,
  setHis,
}) {
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedRemark, doSetRemark] = useState("");
  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    padding: "20px 60px 20px 60px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
    borderRadius: "5px",
    zIndex: 2,
  };

  const buttonContainerStyle = {
    marginTop: "25px",
    display: "flex",
    justifyContent: "center",
  };

  const buttonStyle = {
    margin: "0 5px",
    padding: "4px 10px",
    borderRadius: "3px",
    border: "none",
    cursor: "pointer",
    fontSize: "12px",
  };

  const selectDate = (event) => {
    const date = event.target.value;
    if (new Date(date) <= new Date()) {
      return alert("Select Valid Date");
    }
    setSelectedDate(date);
    setDate(date);
  };

  const doRemark = (event) => {
    const remark = event.target.value;
    doSetRemark(remark);
    setR(remark);
  };

  return (
    <div className="modal" style={modalStyle}>
      {showContent && !loader && (
        <div className="modal-content">
          <h2 style={{ marginBottom: "3px" }}>Confirm Order</h2>
          <p style={{ fontWeight: 600 }}>Buyer</p>
          <FilterBox
            options={data}
            placeholder={"Choose an Account"}
            onOptionSelect={setF}
            moreStyles={{ width: "" }}
          />
          <p style={{ fontWeight: 600, marginTop: "2px" }}>Ship To</p>
          <FilterBox
            options={data2}
            placeholder={"Choose an Account"}
            onOptionSelect={setFF}
            moreStyles={{ width: "" }}
          />
          <p style={{ fontWeight: 600, marginTop: "2px" }}>Transport</p>
          <FilterBox
            options={tportoptions}
            placeholder={"Choose an Account"}
            onOptionSelect={setTT}
            moreStyles={{ width: "" }}
          />
          <div className="mitem">
            <div>Bale:</div>
            <div>{totalCheckboxChecked}</div>
          </div>
          <div className="mitem">
            <div>Pcs</div>
            <div>{totalPcs}</div>
          </div>
          <div className="mitem">
            <div>Qty</div>
            <div>{totalQty}</div>
          </div>
          <div className="mitem1">
            <label htmlFor="ddate">Delivery Date</label>
            <input
              type="date"
              name="ddate"
              id="ddate"
              value={selectedDate}
              onChange={selectDate}
            />
          </div>
          <div className="mitem1">
            <div>Remark</div>
            <div>
              <textarea
                value={selectedRemark}
                onChange={doRemark}
                name="remark"
                id="remarks"
                cols="20"
                rows="5"
              ></textarea>
            </div>
          </div>
          <div className="modal-buttons" style={buttonContainerStyle}>
            <button style={buttonStyle} onClick={onPlaceOrder}>
              Place Order
            </button>
            <button style={buttonStyle} onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      )}
      {loader && <CircularProgress />}
      {requestStatus.status && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <div id="tick-mark" className="tick-animation"></div>
          <p style={{ marginTop: "20px", textAlign: "center" }}>
            {requestStatus.message}
          </p>
          <button
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
            onClick={onCloseok}
          >
            Ok
          </button>
          <button
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
            onClick={() => setHis()}
          >
            View
          </button>
        </div>
      )}
      {showAlert.status && (
        <CustomAlert message={showAlert.message} onClose={closeAlert} />
      )}
    </div>
  );
}

const CustomAlert = ({ message, onClose }) => {
  return (
    <div className="custom-alert-overlay">
      <div className="custom-alert">
        <span className="close-button" onClick={onClose}>
          &times;
        </span>
        <p>{message}</p>
      </div>
    </div>
  );
};

const MyRequests = ({
  loginname,
  sortno,
  tradeName,
  shadeno,
  ptype,
  accounts,
  serverurl,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [requests, SetRequests] = useState([]);
  const [tn, setTn] = useState("");
  const [sn, setSn] = useState("");
  const [shn, setShn] = useState("");
  const [pn, setpn] = useState("");
  const [stat, setSt] = useState("");
  const [src, setSrc] = useState("");
  const [buy, setBuy] = useState("");
  const [dateS, setDateS] = useState("xxxx-xx-xx");
  const [dateE, setDateE] = useState("xxxx-xx-xx");
  const [clickedEno, setClickedEno] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [showFbtn, setShowFbtn] = useState(true);
  const [activeTab, setActiveTab] = useState("bstock");

  const contentRef = useRef(null);

  const history = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    setTimeout(() => {
      const footerElement = document.getElementById("footer");
      const tableContainerElement = document.getElementById("table-container");

      if (footerElement && tableContainerElement) {
        const footerOffset = footerElement.offsetTop;
        const tableContainerOffset = tableContainerElement.offsetTop;
        const calculatedHeight = footerOffset - tableContainerOffset - 2;
        tableContainerElement.style.height = `${calculatedHeight}px`;
      }
    }, 250);
  }, [showTable, showFilters]);

  const fetchRequests = async () => {
    setIsLoading(true);
    setIsLoading2(true);
    try {
      const response = await axiosInstance.get(
        `/api/data/getRequests/${loginname}`
      );
      SetRequests(response.data.result);
      setIsLoading(false);
      setIsLoading2(false);
      setShowTable(true);
      setTimeout(() => {
        const footerElement = document.getElementById("footer");
        const tableContainerElement =
          document.getElementById("table-container");

        if (footerElement && tableContainerElement) {
          const footerOffset = footerElement.offsetTop;
          const tableContainerOffset = tableContainerElement.offsetTop;
          const calculatedHeight = footerOffset - tableContainerOffset - 2;
          tableContainerElement.style.height = `${calculatedHeight}px`;
        }
      }, 250);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const filteredReqData = async () => {
    setIsLoading2(true);
    setShowFbtn(true);
    setShowFilters(false);
    try {
      const response = await axiosInstance.get(
        `/api/data/getFRequests/${loginname}/${
          tn == "" ? "x" : tn
        }/${sn == "" ? "x" : sn}/${encodeURIComponent(shn == "" ? "x" : shn)}/${
          pn == "" ? "x" : pn
        }/${buy == "" ? "x" : buy}/${encodeURIComponent(
          dateS
        )}/${encodeURIComponent(dateE)}/${stat == "" ? "x" : stat}/${
          src == "" ? "x" : src
        }`
      );
      SetRequests(response.data.result);
      setIsLoading2(false);
      setShowTable(true);
      setTimeout(() => {
        const footerElement = document.getElementById("footer");
        const tableContainerElement =
          document.getElementById("table-container");

        if (footerElement && tableContainerElement) {
          const footerOffset = footerElement.offsetTop;
          const tableContainerOffset = tableContainerElement.offsetTop;
          const calculatedHeight = footerOffset - tableContainerOffset - 2;
          tableContainerElement.style.height = `${calculatedHeight}px`;
        }
      }, 250);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLink = (rowNumber) => {
    setShowModal(true);
    setClickedEno(rowNumber);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // const exportToExcel = () => {
  //   // Create a new workbook
  //   const workbook = XLSX.utils.book_new();

  //   // Convert the table data into a worksheet
  //   const worksheet = XLSX.utils.table_to_sheet(
  //     document.getElementById("bookingTable")
  //   );

  //   // Add the worksheet to the workbook
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  //   // Save the workbook as an Excel file
  //   XLSX.writeFile(workbook, `My Bookings.xlsx`);
  // };

  const exportToExcel = () => {
    // Get the table element
    const table = document.getElementById("bookingTable");

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Create a new worksheet
    const worksheet = XLSX.utils.aoa_to_sheet([]);

    // Get the number of rows in the table
    const rowCount = table.rows.length;

    // Loop through each row in the table
    for (let i = 0; i < rowCount; i++) {
      const row = table.rows[i];
      const rowData = [];

      // Get the number of cells in the row
      const cellCount = row.cells.length;

      // Loop through each cell in the row
      for (let j = 0; j < cellCount; j++) {
        const cell = row.cells[j];

        // Check if this is the column where you want each line to appear on a new line
        if (cell.classList.contains("remark-lr")) {
          // Extract text from each <pre> element and add it to the cell content with line breaks
          const preElements = cell.getElementsByTagName("pre");
          let cellContent = "";
          for (let k = 0; k < preElements.length; k++) {
            cellContent += preElements[k].textContent.trim();
            if (k < preElements.length - 1) {
              cellContent += "\r\n"; // Add line break if not the last line
            }
          }
          rowData.push(cellContent);
        } else {
          // For other columns, add the cell's text directly
          rowData.push(cell.textContent);
        }
      }

      // Add the row data to the worksheet
      XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: -1 });
    }

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Save the workbook as an Excel file
    XLSX.writeFile(workbook, `My Bookings.xlsx`);
  };

  // const handleDownloadPDF = () => {
  //   if (contentRef.current) {
  //     const printableContent = contentRef.current;
  //     html2pdf()
  //       .set({ margin: 1, filename: `My Bookings.pdf`, image: { type: 'jpeg', quality: 0.98 } })
  //       .from(printableContent)
  //       .save();
  //   }
  // };

  const handleDownloadPDF = () => {
    if (contentRef.current) {
      const printableContent = contentRef.current;
      const width = printableContent.scrollWidth; // Get the width of the table
      const additionalSpace = window.innerWidth < 900 ? 200 : 0; // Check device width
      console.log(additionalSpace)
  
      html2pdf()
        .set({
          margin: 1,
          filename: `My_Bookings.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2, width: width + additionalSpace }, // Set width + additional space
          jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }
        })
        .from(printableContent)
        .save();
    }
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.keyCode === 13) {
        const button = document.getElementById("refresh");
        if (button) {
          button.click();
        }
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div className="container">
      <div className="ostab-content ">
        <>
          {!isLoading && (
            <>
              {showFbtn && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="filter-btn"
                    onClick={() => {
                      setShowFilters(!showFilters);
                      setShowFbtn(false);
                    }}
                  >
                    {showFilters ? "Hide" : "Show"} Filters
                  </button>
                  <p
                    style={{
                      marginLeft: "5px",
                      color: "red",
                      fontStyle: "italic",
                    }}
                  >
                    {dateE != "xxxx-xx-xx" ||
                    dateS != "xxxx-xx-xx" ||
                    buy ||
                    sn ||
                    tn ||
                    shn ||
                    pn ||
                    stat ? (
                      <>
                        Showing Results for:{" "}
                        {dateS != "xxxx-xx-xx" && `${dateS}`}{" "}
                        {dateE != "xxxx-xx-xx" && `${dateE}`}{" "}
                        {buy && `"${buy}"`} {sn && `"${sn}"`} {tn && `"${tn}"`}{" "}
                        {shn && `"${shn}"`} {pn && `"${pn}"`}{" "}
                        {stat && `"${stat}"`}{" "}
                      </>
                    ) : (
                      <></>
                    )}
                  </p>
                </div>
              )}
              {showFilters && (
                <div className="filter-boxes">
                  <div className="filter-item">
                    <p
                      onClick={() => {
                        setShowFbtn(true);
                        setShowFilters(false);
                      }}
                      style={{
                        cursor: "pointer",
                        border: "2px solid #0B2B43",
                        borderRadius: "50%",
                        padding: "5px 10px 5px 10px",
                        color: "white",
                        backgroundColor: "#0B2B43",
                      }}
                    >
                      X
                    </p>
                  </div>
                  <div className="filter-item filter-item2 ">
                    <input
                      className="filterip"
                      type="date"
                      placeholder="Date[FROM]"
                      value={dateS}
                      onChange={(e) => {
                        setDateS(e.target.value);
                        setShowTable(false);
                      }}
                      style={{
                        width: "150px",
                        padding: "8px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        height: "33.45px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                    className="filter-item filter-item2"
                  >
                    <p
                      style={{
                        marginRight: "15px",
                      }}
                    >
                      To
                    </p>
                    <input
                      className="filterip fip2"
                      type="date"
                      placeholder="DATE[TO]"
                      value={dateE}
                      onFocus={(event) => event.target.select()}
                      onChange={(e) => {
                        setDateE(e.target.value);
                        setShowTable(false);
                      }}
                      style={{
                        width: "150px",
                        padding: "8px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        height: "33.46px",
                      }}
                    />
                  </div>
                  <div className="filter-item filter-item2 by">
                    <Select
                      value={buy ? { value: buy, label: buy } : null}
                      options={accounts.map((b) => ({ value: b, label: b })).sort((a, b) => a.label.localeCompare(b.label))}
                      placeholder={"Buyer"}
                      isClearable={true}
                      onChange={(option) => {
                        setBuy(option == null ? "" : option.label);
                        setShowTable(false);
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "150px", // Adjust width as needed
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          maxHeight: "200px", // Adjust height as needed
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 6,
                        }),
                      }}
                    />
                  </div>
                  <div className="filter-item filter-item2 sn sn1">
                    <Select
                      value={sn ? { value: sn, label: sn } : null}
                      options={sortno.map((b) => ({ value: b, label: b })).sort((a, b) => a.label.localeCompare(b.label))}
                      placeholder={"SortNo"}
                      isClearable={true}
                      onChange={(option) => {
                        setSn(option == null ? "" : option.label);
                        setShowTable(false);
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "150px", // Adjust width as needed
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          maxHeight: "200px", // Adjust height as needed
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 6,
                        }),
                      }}
                    />
                  </div>
                  <div className="filter-item tn filter-item2 sn1">
                    <Select
                      value={tn ? { value: tn, label: tn } : null}
                      options={tradeName.map((b) => ({ value: b, label: b })).sort((a, b) => a.label.localeCompare(b.label))}
                      placeholder={"TradeName"}
                      isClearable={true}
                      onChange={(option) => {
                        setTn(option == null ? "" : option.label);
                        setShowTable(false);
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "150px", // Adjust width as needed
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          maxHeight: "200px", // Adjust height as needed
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 6,
                        }),
                      }}
                    />
                  </div>
                  <div className="filter-item sn filter-item2 pct">
                    <Select
                      value={shn ? { value: shn, label: shn } : null}
                      options={shadeno.map((b) => ({ value: b, label: b })).sort((a, b) => a.label.localeCompare(b.label))}
                      placeholder={"Shade"}
                      isClearable={true}
                      onChange={(option) => {
                        setShn(option == null ? "" : option.label);
                        setShowTable(false);
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "150px", // Adjust width as needed
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          maxHeight: "200px", // Adjust height as needed
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 6,
                        }),
                      }}
                    />
                  </div>
                  <div className="filter-item filter-item2 pct">
                    <Select
                      value={pn ? { value: pn, label: pn } : null}
                      options={ptype.map((b) => ({ value: b, label: b })).sort((a, b) => a.label.localeCompare(b.label))}
                      placeholder={"Pkg"}
                      isClearable={true}
                      onChange={(option) => {
                        setpn(option == null ? "" : option.label);
                        setShowTable(false);
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "150px", // Adjust width as needed
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          maxHeight: "200px", // Adjust height as needed
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 6,
                        }),
                      }}
                    />
                  </div>
                  <div className="filter-item filter-item2 pct">
                    <Select
                      value={stat ? { value: stat, label: stat } : null}
                      options={[
                        { value: "CANCELLED", label: "CANCELLED" },
                        { value: "CONFIRMED", label: "CONFIRMED" },
                        { value: "PENDING", label: "PENDING" },
                      ]}
                      placeholder={"Status"}
                      isClearable={true}
                      onChange={(option) => {
                        setSt(option == null ? "" : option.label);
                        setShowTable(false);
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "150px", // Adjust width as needed
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          maxHeight: "200px", // Adjust height as needed
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 6,
                        }),
                      }}
                    />
                  </div>
                  {/* <div className="filter-item filter-item2 pct">
                    <Select
                      value={src ? { value: src, label: src } : null}
                      options={[
                        { value: "Catalogue", label: "Catalogue" },
                        { value: "Order Entry", label: "Order Entry" },
                        { value: "Bale Stock", label: "Bale Stock" },
                      ]}
                      placeholder={"Source"}
                      isClearable={true}
                      onChange={(option) => {
                        setSrc(option == null ? "" : option.label);
                        setShowTable(false);
                      }}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "150px", // Adjust width as needed
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          maxHeight: "200px", // Adjust height as needed
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 6,
                        }),
                      }}
                    />
                  </div> */}
                  <div className="filter-item filter-item2">
                    <button
                      id="refresh"
                      onClick={() => {
                        filteredReqData();
                      }}
                      style={{
                        width: "80px",
                        margin: 0,
                        fontSize: "12px",
                        padding: "10px",
                        height: "auto",
                      }}
                    >
                      Search
                    </button>
                    <button
                      onClick={() => {
                        setDateE("xxxx-xx-xx");
                        setDateS("xxxx-xx-xx");
                        setBuy("");
                        setSn("");
                        setTn("");
                        setpn("");
                        setShn("");
                        setSrc("");
                        setSt("");
                        fetchRequests();
                      }}
                      style={{
                        width: "80px",
                        margin: 0,
                        fontSize: "12px",
                        padding: "10px",
                        height: "auto",
                      }}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </>
        {isLoading2 ? (
          <CircularProgress />
        ) : requests.length > 0 ? (
          <div
            style={{ backgroundColor: "white", padding: 10 }}
            className="table-container"
            id="table-container"
          >
            {showTable ? (
              <table
                id="bookingTable"
                className="responsive-table"
                ref={contentRef}
              >
                <thead>
                  <tr className="sticky3">
                    <th className="sticky">Req no</th>
                    <th>Date</th>
                    <th>Buyer</th>
                    <th>Item</th>
                    <th>Design</th>
                    <th>Shd</th>                                                            
                    <th>Qty</th>
                    <th>Del Date</th>
                    <th>Remark</th>
                    <th>Status</th>
                    <th>SO NO</th>
                  </tr>
                </thead>
                <tbody>
                  {requests.map((row) => (
                    <tr>
                      <td
                        className="sticky"
                        style={{
                          cursor: "pointer",
                          fontWeight: 500,
                          textDecoration: "underline",
                          textDecorationColor: "#0B2B43",
                        }}
                        onClick={() => handleLink(row.eno)}
                      >
                        {row.eno}
                      </td>
                      <td>{row.date && row.date.split("-").join("/")}</td>
                      <td>{row.buyer}</td>
                      <td>{row.item}</td>
                      <td>{row.design}</td>
                      <td>{row.shade}</td>                    
                      <td>{row.qty}</td>                      
                      <td>
                        {row.deldate &&
                          (row.deldate.includes("-190")
                            ? ""
                            : row.deldate.split("-").join("/"))}
                      </td>
                      <td>{`${row.remark1}  ${row.remark}`}</td>
                      <td>{row.status}</td>
                      <td>{row.sono ? row.sono : ""}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null}
          </div>
        ) : (
          <div>Zero records found.</div>
        )}
        {showModal && (
          <PModal rowNumber={clickedEno} onClose={handleCloseModal} />
        )}
      </div>
      <div id="footer" className="footer">
        <div className="total-details">
          <ul>
            <li>Total: {requests.length.toLocaleString("en-IN")}</li>            
            <li>
              Total Qty:{" "}
              {requests
                .reduce((acc, obj) => acc + parseFloat(obj.qty), 0)
                .toLocaleString("en-IN")}
            </li>
          </ul>
        </div>
        <div>
          <button
            className="footbut"
            onClick={exportToExcel}
            style={{
              width: "80px",
              margin: 0,
              fontSize: "12px",
              padding: "10px",
              height: "auto",
            }}
          >
            Excel
          </button>
          <button
            className="footbut"
            onClick={handleDownloadPDF}
            style={{
              width: "80px",
              margin: 0,
              fontSize: "12px",
              padding: "10px",
              height: "auto",
            }}
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

const PModal = ({ rowNumber, onClose }) => {
  return (
    <div className="modal1">
      <div className="modal-content1">
        <span className="close1" onClick={onClose}>
          &times;
        </span>
        <PrintForm rowNumber={rowNumber} />
      </div>
    </div>
  );
};

export { FilterBox };
export default Dashboard;
