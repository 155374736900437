import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../api/axios";
import { FilterBox } from "./Dashboard";
import { CircularProgress } from "@mui/material";
import "./dashboard.css";
import Select, { components } from "react-select";

const Catalogue = ({ acounts, haste, dlist, ilist, loginname, serverurl }) => {
  const [items, setItems] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [requestStatus, setRequestStatus] = useState({
    success: false,
    message: "",
  });
  const [showContent, setShowContent] = useState(true);
  const [modalLoader, setModalLoader] = useState(false);
  const [ddate, setDdate] = useState("");
  const [remark, setRemark] = useState("");
  const [bookAcc, setBookAcc] = useState("");
  const [hasteAcc, setHasteAcc] = useState("");
  const [design, setDesign] = useState("");
  //const [shade, setShade] = useState("");
  const [item, setItem] = useState("");
  const [color, setColor] = useState("");
  const [sdesign, setSdesign] = useState("");
  const [colorlist, setColorList] = useState([]);
  const [pattern, setPattern] = useState("");
  const [catlist, setCatlist] = useState([]);
  const [cat, setCat] = useState("");
  const [sqty, setSqty] = useState(null);
  const [patternlist, setPatternList] = useState([]);
  const [showCart, setShowCart] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [xsearch, setXsearch] = useState("");
  const [quantities, setQuantities] = useState({});
  const [delDates, setDeldates] = useState({});
  const [remarks, setRemarks] = useState({});
  const [showFbtn, setShowFbtn] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const observer = useRef();
  const selectref = useRef();

  const openModal = () => {
    console.log(selectedRows.length);
    setIsModalOpen(true);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleCart = () => {
    setShowCart((prevShowCart) => !prevShowCart); // Toggle cart visibility
  };

  const closeModalok = () => {
    setIsModalOpen(false);
    setRequestStatus({ success: false, message: "" });
    setShowContent(true);
  };

  const handleSearchChange = (event) => {
    setXsearch(event.target.value); // Update search term state
  };

  const handleSearch = () => {
    const xyz = xsearch.toUpperCase();
    setSearchTerm(xyz);
    setItems([]);
    setPage(1);
    setHasMore(true);
  };

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await axiosInstance.get(`/api/data/getCF`);
        setColorList(response.data.s1);
        setPatternList(response.data.s2);
        setCatlist(response.data.s3);
      } catch (error) {
        console.log(error);
      }
    };
    fetchFilters();
  }, []);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          setPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 0.5 }
    );

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = intersectionObserver;
    if (!showCart && items.length > 0) {
      // Only observe if in catalogue view
      intersectionObserver.observe(
        document.querySelector(".catalog-item:last-child")
      );
    }

    return () => intersectionObserver.disconnect();
  }, [items, hasMore, loading, showCart]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoader1(true);
        setLoading(true);
        const response = await axiosInstance.get(
          `/api/data/catalogue/${loginname}?page=${page}&design=${encodeURIComponent(
            sdesign == "" ? (design == "" ? "x" : design) : sdesign
          )}&item=${encodeURIComponent(
            item == "" ? "x" : item
          )}&pattern=${encodeURIComponent(
            pattern == "" ? "x" : pattern
          )}&color=${encodeURIComponent(
            color == "" ? "x" : color
          )}&cattype=${encodeURIComponent(
            cat == "" ? "x" : cat
          )}&sqty=${encodeURIComponent(sqty ? sqty : "x")}`
        );
        console.log(response.data);
        const newItems = response.data.data;
        if (newItems.length === 0) {
          setHasMore(false);
          setLoader1(false);
          return;
        }
        setItems((prevItems) => [...prevItems, ...newItems]);
        setLoader1(false);
        // const initialQuantities = {};
        // newItems.forEach((item) => {
        //   initialQuantities[item.design] = 1; // Default quantity is 1, you can change it according to your requirement
        // });
        // setQuantities(initialQuantities);
        setShowFilters(false);
        setShowFbtn(true);
        setTimeout(() => {
          const footerElement = document.getElementById("footer");
          const tableContainerElement =
            document.getElementById("catalog-container");

          if (footerElement && tableContainerElement) {
            const footerOffset = footerElement.offsetTop;
            const tableContainerOffset = tableContainerElement.offsetTop;
            const calculatedHeight = footerOffset - tableContainerOffset - 2;
            tableContainerElement.style.height = `${calculatedHeight}px`;
          }
        }, 0);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };    
      fetchData()      
  }, [page, sdesign]);

  const handleSearhClick = (fresh = false) => {
    const fetchData = async () => {
      try {
        setLoader1(true);
        setLoading(true);
        const response = await axiosInstance.get(
          `/api/data/catalogue/${loginname}?page=${page}&design=${encodeURIComponent(
            fresh ? "x" : design == "" ? "x" : design
          )}&item=${encodeURIComponent(
            fresh ? "x" : item == "" ? "x" : item
          )}&pattern=${encodeURIComponent(
            fresh ? "x" : pattern == "" ? "x" : pattern
          )}&color=${encodeURIComponent(
            fresh ? "x" : color == "" ? "x" : color
          )}&cattype=${encodeURIComponent(
            fresh ? "x" : cat == "" ? "x" : cat
          )}&sqty=${encodeURIComponent(fresh ? "x" : sqty ? sqty : "x")}`
        );
        console.log(response.data);
        const newItems = response.data.data;
        if (newItems.length === 0) {
          setHasMore(false);
          setLoader1(false);
          return;
        }
        setItems((prevItems) => [...prevItems, ...newItems]);
        setLoader1(false);
        setShowFilters(false);
        setShowFbtn(true);
        setTimeout(() => {
          const footerElement = document.getElementById("footer");
          const tableContainerElement =
            document.getElementById("catalog-container");

          if (footerElement && tableContainerElement) {
            const footerOffset = footerElement.offsetTop;
            const tableContainerOffset = tableContainerElement.offsetTop;
            const calculatedHeight = footerOffset - tableContainerOffset - 2;
            tableContainerElement.style.height = `${calculatedHeight}px`;
          }
        }, 0);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  };
  const handleCheckboxChange = (event, row) => {
    if (event.target.checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, row]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter(
          (selectedRow) => selectedRow.design !== row.design
        )
      );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const footerElement = document.getElementById("footer");
      const tableContainerElement =
        document.getElementById("catalog-container");

      if (footerElement && tableContainerElement) {
        const footerOffset = footerElement.offsetTop;
        const tableContainerOffset = tableContainerElement.offsetTop;
        const calculatedHeight = footerOffset - tableContainerOffset - 2;
        tableContainerElement.style.height = `${calculatedHeight}px`;
      }
    }, 0);
  }, [showFilters]);

  const handleSendDataToDatabase = async () => {
    try {
      if (bookAcc == "" || bookAcc == "x") {
        return alert("Please select a buyer.");
      }
      const itemsWithQuantities = selectedRows.map((item) => ({
        ...item,
        qty: quantities[item.design] || 0,
        rem: remarks[item.design] || "",
      }));
      setShowContent(false);
      setModalLoader(true);
      const data = {
        datarows: { ...itemsWithQuantities },
        bookAcc,
        loginname,
        hasteAcc,
        ddate,
        remark,
      };
      const response = await axiosInstance.post(`/api/data/cattdata`, data);
      setModalLoader(false);
      setRequestStatus({ status: true, message: response.data.message });
      setBookAcc("");
      setHasteAcc("");
      setDdate("");
      setRemark("");
      setSelectedRows([]);
      setQuantities({});
      setRemarks({});
      setModalLoader(false);
      data.rno = response.data.requestNo;
      const mailResponse = await axiosInstance.post("/api/data/mailer", data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateSelect = (date) => {
    console.log(date);
    setDdate(date); // Here you can use the selected date
  };

  const handleRemark = (remark) => {
    setRemark(remark);
  };

  const handleQtyChange = (id, event) => {
    const { value } = event.target;
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: parseInt(value, 10) || 0,
    }));
  };
  const handleDDChange = (id, event) => {
    const { value } = event.target;
    setDeldates((prevQuantities) => ({
      ...prevQuantities,
      [id]: value,
    }));
  };
  const handleRemarkChange = (id, event) => {
    const { value } = event.target;
    setRemarks((prevQuantities) => ({
      ...prevQuantities,
      [id]: value,
    }));
  };

  const CustomControl = (props) => (
    <components.Control {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        height={"20px"}
        width={"20px"}
      >
        <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
      </svg>
      {props.children}
    </components.Control>
  );

  return (
    <div className="container">
      {showFbtn && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          {!(item || design || color || pattern) && (
            <div
              className="filter-item cfi"
              style={{ marginBottom: "0px", flex: "1" }}
            >
              <Select
                ref={selectref}
                value={sdesign ? { value: sdesign, label: sdesign } : null}
                options={dlist
                  .map((b) => ({ value: b, label: b }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                placeholder={"Search"}
                isClearable={true}
                components={{ Control: CustomControl }}
                onChange={(option) => {
                  setItems([]);
                  setPage(1);
                  setHasMore(true);
                  setSdesign(option == null ? "" : option.label);
                  if (option == null && selectref.current) {
                    selectref.current.blur();
                  }
                }}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: "100%",
                    paddingInlineStart: "10px",
                    borderRadius: "25px",
                    backgroundColor: "lightgrey",
                    textAlign: "left",
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    maxHeight: "200px",
                    textAlign: 'left'
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 6,
                  }),
                  container: (provided) => ({
                    ...provided,
                    width: "90%",
                  }),
                }}
              />
            </div>
          )}
          <button
            className="filter-btn"
            onClick={() => {
              if (item || design || color || pattern) {
                setItem("");
                setDesign("");
                setColor("");
                setPattern("");
                setSdesign("");
                setItems([]);
                setPage(1);
                setHasMore(true);
                handleSearhClick(true)
              } else {
                setShowFilters(!showFilters);
                setShowFbtn(false);
              }
            }}
          >
            {item || design || color || pattern ? "Clear" : "More"} Filters
          </button>
          <p style={{ marginLeft: "5px", color: "red", fontStyle: "italic" }}>
            {item || design || color || pattern ? (
              <>
                Showing Results for: {item && `"${item}"`}{" "}
                {design && `"${design}"`} {color && `"${color}"`}{" "}
                {pattern && `"${pattern}"`}{" "}
              </>
            ) : (
              <></>
            )}
          </p>
        </div>
      )}
      {showFilters && (
        <div className="filter-boxes ">
          <div className="filter-item">
            <p
              onClick={() => {
                setShowFbtn(true);
                setShowFilters(false);
              }}
              style={{
                cursor: "pointer",
                border: "2px solid #0B2B43",
                borderRadius: "50%",
                padding: "5px 10px 5px 10px",
                color: "white",
                backgroundColor: "#0B2B43",
              }}
            >
              X
            </p>
          </div>
          <div className="filter-item cfi">
            <Select
              value={item ? { value: item, label: item } : null}
              options={ilist
                .map((b) => ({ value: b, label: b }))
                .sort((a, b) => a.label.localeCompare(b.label))}
              placeholder={"Item"}
              isClearable={true}
              onChange={(option) => {
                setItem(option == null ? "" : option.label);
              }}
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "150px", // Adjust width as needed
                }),
                menuList: (provided) => ({
                  ...provided,
                  maxHeight: "200px", // Adjust height as needed
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 6,
                }),
              }}
            />
          </div>
          <div className="filter-item cfi">
            <Select
              value={design ? { value: design, label: design } : null}
              options={dlist
                .map((b) => ({ value: b, label: b }))
                .sort((a, b) => a.label.localeCompare(b.label))}
              placeholder={"Design"}
              isClearable={true}
              onChange={(option) => {
                setDesign(option == null ? "" : option.label);
              }}
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "150px", // Adjust width as needed
                }),
                menuList: (provided) => ({
                  ...provided,
                  maxHeight: "200px", // Adjust height as needed
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 6,
                }),
              }}
            />
          </div>
          <div className="filter-item cfi">
            <Select
              value={color ? { value: color, label: color } : null}
              options={colorlist
                .map((b) => ({ value: b, label: b }))
                .sort((a, b) => a.label.localeCompare(b.label))}
              placeholder={"Color"}
              isClearable={true}
              onChange={(option) => {
                setColor(option == null ? "" : option.label);
              }}
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "150px", // Adjust width as needed
                }),
                menuList: (provided) => ({
                  ...provided,
                  maxHeight: "200px", // Adjust height as needed
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 6,
                }),
              }}
            />
          </div>
          <div className="filter-item cfi">
            <Select
              value={pattern ? { value: pattern, label: pattern } : null}
              options={patternlist
                .map((b) => ({ value: b, label: b }))
                .sort((a, b) => a.label.localeCompare(b.label))}
              placeholder={"Pattern"}
              isClearable={true}
              onChange={(option) => {
                setPattern(option == null ? "" : option.label);
              }}
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "150px", // Adjust width as needed
                }),
                menuList: (provided) => ({
                  ...provided,
                  maxHeight: "200px", // Adjust height as needed
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 6,
                }),
              }}
            />
          </div>
          <div className="filter-item cfi">
            <Select
              value={cat ? { value: cat, label: cat } : null}
              options={catlist
                .map((b) => ({ value: b, label: b }))
                .sort((a, b) => a.label.localeCompare(b.label))}
              placeholder={"Catalogue"}
              isClearable={true}
              onChange={(option) => {
                setCat(option == null ? "" : option.label);
              }}
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "150px", // Adjust width as needed
                }),
                menuList: (provided) => ({
                  ...provided,
                  maxHeight: "200px", // Adjust height as needed
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 6,
                }),
              }}
            />
          </div>
          <div className="filter-item cfi">
            <input
              type="number"
              min={0}
              max={200}
              placeholder="Qty >"
              style={{
                width: "150px",
                height: "38px",
                padding: "5px",
                textAlign: "right",
                fontWeight: "bold",
                fontSize: "inherit",
              }}
              value={sqty}
              onFocus={(e) => {
                e.target.select();
              }}
              onChange={(e) => {
                const inputNumber = e.target.value;
                if (!isNaN(inputNumber) && inputNumber <= 200) {
                  setSqty(inputNumber);
                }
              }}
            />
          </div>
          <div className="filter-item filter-item2">
            <button
              id="search"
              onClick={() => {
                setItems([]);
                setPage(1);
                setHasMore(true);
                setSdesign("");
                handleSearhClick();
              }}
              style={{
                width: "80px",
                margin: 0,
                fontSize: "12px",
                padding: "10px",
                height: "auto",
              }}
            >
              Search
            </button>
          </div>
        </div>
      )}
      {loader1 && page === 1 && (
        <>
          <CircularProgress />
        </>
      )}
      {!loader ? (
        showCart ? ( // If showCart is true, render cart
          <div className="catalog-container">
            {items.length == 0 && !loader1 && (
              <>
                <p>Zero records found.</p>
              </>
            )}
            {selectedRows.map((item, index) => (
              <div key={item.design} className="catalog-item">
                <div className="catalog-item-image-container thumbnail-containerc">
                  <img
                    src={item.image || "logo.jpg"}
                    alt={item.design}
                    className="catalog-image thumbnail"
                    onClick={() => handleImageClick(item.image || "logo.jpg")}
                  />
                </div>
                {/* <div className="catalog-item-details">
                  <div className="details-column">
                    <p>
                      <strong>Item:</strong> {item.item}
                    </p>
                    <p>
                      <strong>Design:</strong> {item.design}
                    </p>
                  </div>
                  <div className="details-column">
                    <p>
                      <strong>Color:</strong> {item.search1}
                    </p>
                    <p>
                      <strong>Pattern:</strong> {item.search2}
                    </p>
                  </div>
                  <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "end"}}>
                  <div className="qty-column">
                    <label htmlFor="qty">
                      <strong>Qty:</strong>
                    </label>
                    <input
                      type="number"
                      id="qty"
                      value={quantities[item.design] || ""}
                      onChange={(e) => handleQtyChange(item.design, e)}
                      disabled={
                        !selectedRows.some((x) => x.design == item.design)
                      }
                    />
                  </div>
                  <div className="qty-column">
                    <label htmlFor="qty">
                      <strong>Remark:</strong>
                    </label>
                    <input
                      type="text"
                      id="qty"
                      style={{width: '110px', height: '23px'}}
                      value={remarks[item.design] || ""}
                      onChange={(e) => handleRemarkChange(item.design, e)}
                      disabled={
                        !selectedRows.some((x) => x.design == item.design)
                      }
                    />
                  </div>
                  </div>
                </div> */}
                <div className="catalog-item-details">
                  <div className="details-column">
                    <h2 style={{ color: "#0B2B43", fontSize: "28px" }}>
                      {item.design}
                    </h2>
                    <p>
                      <strong style={{ color: "#0B2B43" }}>{item.resto}</strong>
                      <br></br>
                      {item.rate ? (
                        <strong style={{ color: "#0B2B43" }}>
                          Ex. Mil Rate: {item.rate}
                        </strong>
                      ) : null}
                      {item.rate && <br></br>}
                      {item.item}
                      <br></br>
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "end",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={selectedRows.some(
                        (selectedRow) => selectedRow.design === item.design
                      )}
                      onChange={() =>
                        handleCheckboxChange(
                          {
                            target: {
                              checked: !selectedRows.some(
                                (selectedRow) =>
                                  selectedRow.design === item.design
                              ),
                            },
                          },
                          item
                        )
                      }
                      className="catalog-checkbox"
                    />
                    <div className="qty-column">
                      <input
                        type="number"
                        id="qty"
                        className="ct-ip"
                        placeholder="Qty"
                        style={{
                          width: "110px",
                          marginLeft: "5px",
                          textAlign: "right",
                        }}
                        value={quantities[item.design] || ""}
                        onChange={(e) => handleQtyChange(item.design, e)}
                        disabled={
                          !selectedRows.some((x) => x.design == item.design)
                        }
                      />
                    </div>
                    <div className="qty-column">
                      <input
                        type="text"
                        id="qty"
                        className="ct-ip"
                        placeholder="Remark"
                        style={{
                          width: "110px",
                          height: "23px",
                          marginLeft: "5px",
                          marginTop: "5px",
                        }}
                        value={remarks[item.design] || ""}
                        onChange={(e) => handleRemarkChange(item.design, e)}
                        disabled={
                          !selectedRows.some((x) => x.design == item.design)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          // If showCart is false, render catalogue
          <div className="catalog-container" id="catalog-container">
            {items.length == 0 && !loader1 && (
              <>
                <p>Zero records found.</p>
              </>
            )}
            {items.map((item, index) => (
              <div key={item.design} className="catalog-item">
                <div className="catalog-item-image-container thumbnail-containerc">
                  <img
                    src={item.image || "logo.jpg"}
                    alt={item.design}
                    className="catalog-image thumbnail"
                    onClick={() => handleImageClick(item.image || "logo.jpg")}
                  />
                </div>
                {/* <div>
                  <h2>{item.design}</h2>
                </div> */}
                <div className="catalog-item-details">
                  <div className="details-column">
                    <h1 style={{ color: "#0B2B43", fontSize: "26px" }}>
                      {item.design}
                    </h1>
                    <p>
                      <strong style={{ color: "#0B2B43" }}>{item.resto}</strong>
                      <br></br>
                      {item.rate ? (
                        <strong style={{ color: "#0B2B43" }}>
                          Ex. Mil Rate: {item.rate}
                        </strong>
                      ) : null}
                      {item.rate && <br></br>}
                      {item.item}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "end",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={selectedRows.some(
                        (selectedRow) => selectedRow.design === item.design
                      )}
                      onChange={() =>
                        handleCheckboxChange(
                          {
                            target: {
                              checked: !selectedRows.some(
                                (selectedRow) =>
                                  selectedRow.design === item.design
                              ),
                            },
                          },
                          item
                        )
                      }
                      className="catalog-checkbox"
                    />
                    <div className="qty-column">
                      <input
                        type="number"
                        id="qty"
                        placeholder="Qty"
                        className="ct-ip"
                        style={{
                          width: "110px",
                          marginLeft: "5px",
                          textAlign: "right",
                        }}
                        value={quantities[item.design] || ""}
                        onChange={(e) => handleQtyChange(item.design, e)}
                        disabled={
                          !selectedRows.some((x) => x.design == item.design)
                        }
                      />
                    </div>
                    <div className="qty-column">
                      <input
                        type="text"
                        id="qty"
                        placeholder="Remark"
                        className="ct-ip"
                        style={{
                          width: "110px",
                          height: "23px",
                          marginLeft: "5px",
                          marginTop: "10px",
                        }}
                        value={remarks[item.design] || ""}
                        onChange={(e) => handleRemarkChange(item.design, e)}
                        disabled={
                          !selectedRows.some((x) => x.design == item.design)
                        }
                      />
                    </div>
                  </div>
                </div>
                {index === items.length - 1 && loading && (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 4,
                      bottom: 50,
                      left: "50%",
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}
              </div>
            ))}
          </div>
        )
      ) : (
        <div>
          <CircularProgress />
        </div>
      )}
      <div id="footer" className="footer">
        <div className="total-details">
          <ul>
            <li>Total: {selectedRows.length}</li>
          </ul>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "4px",
          }}
          className="footer-button"
        >
          <button
            className="footbut"
            style={{
              width: "80px",
              margin: 0,
              fontSize: "12px",
              padding: "10px",
              height: "auto",
            }}
            onClick={() => toggleCart()}
          >
            {showCart ? "Hide Cart" : "Show Cart"}
          </button>
          <button
            className="footbut"
            style={{
              width: "80px",
              margin: 0,
              fontSize: "12px",
              padding: "10px",
              height: "auto",
              cursor: selectedRows.length > 0 ? "pointer" : "not-allowed",
            }}
            onClick={openModal}
            disabled={selectedRows.length == 0}
          >
            Submit
          </button>
        </div>
      </div>
      {isModalOpen && (
        <Modal
          onClose={closeModal}
          onPlaceOrder={handleSendDataToDatabase}
          data={acounts}
          data2={haste}
          setFF={(option) => setHasteAcc(option)}
          setF={(option) => setBookAcc(option)}
          totalCheckboxChecked={selectedRows.length}
          setDate={handleDateSelect}
          setR={handleRemark}
          showContent={showContent}
          requestStatus={requestStatus}
          loader={modalLoader}
          onCloseok={closeModalok}
        />
      )}
      {selectedImage && (
        <div className="overlaycat" onClick={handleClose}>
          <img src={selectedImage} alt="Selected" className="large-imagecat" />
        </div>
      )}
    </div>
  );
};

function Modal({
  onClose,
  onPlaceOrder,
  data,
  data2,
  setFF,
  setF,
  totalCheckboxChecked,
  setDate,
  setR,
  requestStatus,
  showContent,
  loader,
  onCloseok,
  showAlert,
  closeAlert,
  setHis,
}) {
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedRemark, doSetRemark] = useState("");
  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    padding: "20px 60px 20px 60px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
    borderRadius: "5px",
    zIndex: 2,
  };

  const buttonContainerStyle = {
    marginTop: "25px",
    display: "flex",
    justifyContent: "center",
  };

  const buttonStyle = {
    margin: "0 5px",
    padding: "4px 10px",
    borderRadius: "3px",
    border: "none",
    cursor: "pointer",
    fontSize: "12px",
  };

  const selectDate = (event) => {
    const date = event.target.value;
    if (new Date(date) <= new Date()) {
      return alert("Select Valid Date");
    }
    setSelectedDate(date);
    setDate(date);
  };

  const doRemark = (event) => {
    const remark = event.target.value;
    doSetRemark(remark);
    setR(remark);
  };

  return (
    <div className="modal" style={modalStyle}>
      {showContent && !loader && (
        <div className="modal-content">
          <h2 style={{ marginBottom: "3px" }}>Confirm Order</h2>
          <p style={{ fontWeight: 600 }}>Buyer</p>
          <FilterBox
            options={data}
            placeholder={"Choose an Account"}
            onOptionSelect={setF}
            moreStyles={{ width: "" }}
          />
          <p style={{ fontWeight: 600, marginTop: "2px" }}>Ship To</p>
          <FilterBox
            options={data2}
            placeholder={"Choose an Account"}
            onOptionSelect={setFF}
            moreStyles={{ width: "" }}
          />
          <div className="mitem">
            <div>Total:</div>
            <div>{totalCheckboxChecked}</div>
          </div>
          <div className="mitem1">
            <label htmlFor="ddate">Delivery Date</label>
            <input
              type="date"
              name="ddate"
              id="ddate"
              value={selectedDate}
              onChange={selectDate}
            />
          </div>
          <div className="mitem1">
            <div>Remark</div>
            <div>
              <textarea
                value={selectedRemark}
                onChange={doRemark}
                name="remark"
                id="remarks"
                cols="20"
                rows="5"
              ></textarea>
            </div>
          </div>
          <div className="modal-buttons" style={buttonContainerStyle}>
            <button style={buttonStyle} onClick={onPlaceOrder}>
              Place Order
            </button>
            <button style={buttonStyle} onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      )}
      {loader && <CircularProgress />}
      {requestStatus.status && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <div id="tick-mark" className="tick-animation"></div>
          <p style={{ marginTop: "20px", textAlign: "center" }}>
            {requestStatus.message}
          </p>
          <button
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
            onClick={onCloseok}
          >
            Ok
          </button>
        </div>
      )}
    </div>
  );
}

export default Catalogue;
