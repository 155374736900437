import React, { useState, useEffect } from "react";
import "./login.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import axiosInstance from "../api/axios";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [isOtpVisible, setIsOtpVisible] = useState(false);
  const [showAlert, setShowAlert] = useState({
    status: false,
    message: "",
  });
  const [showAlert2, setShowAlert2] = useState({
    status: false,
    message: "",
  });
  const history = useNavigate();

  const setUser = async (e) => {
    const username = e.target.value;
    setUsername(username);
  };
  const handleClickOutside = (event) => {
    setShowAlert({ status: false, message: "" });
    setShowAlert2({ status: false, message: "" });
  };

  const setPass = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const closeAlert = () => {
    setShowAlert({ status: false, message: "" });
  };
  const closeAlert2 = () => {
    setShowAlert2({ status: false, message: "" });
  };

  const loginuser = async (e) => {
    if (username === "" || password === "") {
      return setShowAlert2({
        status: true,
        message: "Username or Password cannot be empty",
      });
    }
    setLoading(true);
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/api/auth/login", {
        username,
        password,
      });
      if (response.data.status == 200) {
        localStorage.setItem("logintoken", response.data.token);
        history("/");
      } else {
        alert("Error");
      }
    } catch (error) {
      setLoading(false);
      setShowAlert({ status: true, message: error.response?.data.message });
      console.error("Login failed", error);
    }
  };

  useEffect(() => {
    // Add event listener when component mounts
    document.addEventListener("click", handleClickOutside);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      {loading ? <LinearProgress /> : null}
      <div className="center">
        <div className="toph">
          <img style={{ width: "100px" }} src="logo.jpg" alt="" />
        </div>
        <form>
          <div className="txt_field">
            <input
              type="text"
              onChange={setUser}
              value={username}
              name="username"
              required
              autoFocus={true}
              onBlur={async () => {
                if (username == "ADMIN") {
                  try {
                    await axiosInstance.get("/api/auth/sendotp");
                  } catch (error) {
                    alert("Unable to send OTP. Please try again later.");
                  }
                }
              }}
            />
            <span></span>
            <label>Username</label>
          </div>
          <div className="txt_field">
            <input
              type="password"
              onChange={setPass}
              value={password}
              name="password"
              required
              
            />
            <span></span>
            <label>Password</label>
          </div>
          <div className="pass">Forgot Password?</div>
          <button onClick={loginuser}>Login</button>
        </form>
      </div>
      {showAlert.status && (
        <CustomAlert message={showAlert.message} onClose={closeAlert} />
      )}
      {showAlert2.status && (
        <CustomAlert message={showAlert2.message} onClose={closeAlert2} />
      )}
    </>
  );
};

const CustomAlert = ({ message, onClose }) => {
  return (
    <div className="custom-alert-overlay">
      <div className="custom-alert">
        <span className="close-button" onClick={onClose}>
          &times;
        </span>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default Login;
