import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto'; // Import Chart.js

const BarGraph = ({ data }) => {
  const chartContainer = useRef(null);
  let barChart = null;

  useEffect(() => {
    // Create the chart when the component mounts
    if (chartContainer && chartContainer.current) {
      // Get labels, values1, and values2 from the data array
      const labels = data.map(item => `${item.month}-${item.year}`);
      const values1 = data.map(item => item.total_balance);
      const values2 = data.map(item => item.cumulative_balance);

      const ctx = chartContainer.current.getContext('2d');

      // Initialize the bar chart with two datasets for each label
      barChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [{
            label: 'Amount',
            data: values1,
            backgroundColor: 'rgba(75, 192, 192, 0.2)', // Customize bar color for value 1
            borderColor: 'rgba(75, 192, 192, 1)', // Customize border color for value 1
            borderWidth: 1,
          },
          {
            label: 'Balance',
            data: values2,
            backgroundColor: 'rgba(255, 99, 132, 0.2)', // Customize bar color for value 2
            borderColor: 'rgba(255, 99, 132, 1)', // Customize border color for value 2
            borderWidth: 1,
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      });
    }

    // Cleanup function to destroy the chart when component unmounts
    return () => {
      if (barChart) {
        barChart.destroy();
      }
    };
  }, [data]);

  return (
    <div>
      <canvas ref={chartContainer} width="285" height="285"></canvas>
    </div>
  );
};

export default BarGraph;
