import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import PrintForm from "./components/PrintForm";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    // Define your logic to set background color based on the current location
    const body = document.querySelector("body");
    if (body) {
      if (location.pathname === "/") {
        body.style.background = "#0B2B43"; // Example color for Home route
      } else if (location.pathname === "/login") {
        body.style.background = "#0B2B43"; // Example color for About route
      } else if (location.pathname === "/print") {
        body.style.background = "white"; // Example color for Contact route
      }
    }
  }, [location]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" exact Component={Dashboard} />
        <Route path="/login" Component={Login} />
        <Route path="/print" Component={PrintForm} />
      </Routes>
    </div>
  );
};

export default App;
