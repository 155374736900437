import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import axiosInstance from '../api/axios';

const MyProfile = ({ loginname, accounts, serverurl }) => {
  const [loader, setLoader] = useState(false);
  const [userdata, setUserdata] = useState(null);
  const [tdata, setTdata] = useState([]);

  const [sortConfig, setSortConfig] = useState({
    key: "diff",
    direction: "desc",
  });

  const sortTable = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = [...tdata].sort((a, b) => {
    const keyA = a[sortConfig.key];
    const keyB = b[sortConfig.key];
    // Custom comparison for date column
    if (sortConfig.key === "date") {
      const dateA = new Date(keyA.split("-").reverse().join("-"));
      const dateB = new Date(keyB.split("-").reverse().join("-"));
      return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
    }
    if (typeof keyA === "number" && typeof keyB === "number") {
      return sortConfig.direction === "asc" ? keyA - keyB : keyB - keyA;
    }
    // Default comparison for other columns
    return sortConfig.direction === "asc"
      ? keyA.localeCompare(keyB)
      : keyB.localeCompare(keyA);
  });

  const getUserData = async () => {
    try {
      setLoader(true);
      console.log("hi", accounts.map(item => item.trim()))
      const response = await axiosInstance.post(
        `/api/data/myprofile`,
        {
            loginname: loginname,
            buyerNames: accounts.map(item => item.trim()),          
        }
      );
      setUserdata(response.data.data);
      setTdata(response.data.pld);
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div className="container">
      <div className="headname">{loginname}</div>
      {!loader ? (
        <>
          <div className="profiledcont">
            <p>
              <strong>
                <span className="pdc-head">Address: </span>{" "}
              </strong>
              <span className="pdc-label">{(userdata && userdata.address) || '-'}</span>
            </p>
            <p>
              <strong>
                <span className="pdc-head">Mobile No: </span>
              </strong>
              <span className="pdc-label">
                {(userdata && userdata.mobileno.split(",").join(", ")) || '-'}
              </span>
            </p>
            <p>
              <strong>
                <span className="pdc-head">Email: </span>
              </strong>
              <span className="pdc-label ">
                {(userdata && userdata.email.split(";").join(", ")) || '-'}
              </span>
            </p>
            <p>
              <strong>
                <span className="pdc-head">GSTIN: </span>
              </strong>
              <span className="pdc-label">
                {userdata &&
                  ((userdata.gstno.trim() == "" ? "-" : userdata.gstno)) || '-'}
              </span>
            </p>
          </div>
          <div
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              marginBottom: "5px",
            }}
          >
            Party Details
          </div>
          <div
            style={{ maxHeight: "50vh", height: "calc(100vh - 50%)" }}
            className="table-container"
          >
            <table className="responsive-table">
              <thead>
                <tr className="sticky3">
                  <th className="sticky" onClick={() => sortTable("account")}>
                    Name{" "}
                    {sortConfig.key === "account" &&
                      (sortConfig.direction === "asc" ? "▲" : "▼")}
                  </th>
                  <th onClick={() => sortTable("closebal")}>
                    Led.Bal
                    {sortConfig.key === "closebal" &&
                      (sortConfig.direction === "asc" ? "▲" : "▼")}
                  </th>
                  <th onClick={() => sortTable("pd")}>
                    SO Value
                    {sortConfig.key === "pd" &&
                      (sortConfig.direction === "asc" ? "▲" : "▼")}
                  </th>
                  <th onClick={() => sortTable("diff")}>
                    Difference
                    {sortConfig.key === "diff" &&
                      (sortConfig.direction === "asc" ? "▲" : "▼")}{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedData.map((row, index) => (
                  <tr key={row.account}>
                    <td style={{ fontSize: "0.9rem" }} className="sticky">
                      <strong>{row.account}</strong>
                    </td>
                    <td
                      className="rightalign"
                      style={{
                        color: String(row.closebal).startsWith("-")
                          ? "blue"
                          : "red",
                      }}
                    >
                      <strong>{row.closebal.toLocaleString("en-IN")}</strong>
                    </td>
                    <td className="rightalign">
                      {row.pd.toLocaleString("en-IN")}
                    </td>
                    <td
                      className="rightalign"
                      style={{
                        color: String(row.diff).startsWith("-")
                          ? "blue"
                          : "red",
                      }}
                    >
                      {row.diff.toLocaleString("en-IN")}
                    </td>
                  </tr>
                ))}
                <tr style={{backgroundColor: '#0B2B43', color: 'white'}}>
                  <td className="rightalign">{tdata.length}</td>
                  <td className="rightalign">
                    {tdata
                      .reduce((acc, obj) => acc + obj.closebal, 0)
                      .toLocaleString("en-IN")}
                  </td>
                  <td className="rightalign">
                    {tdata
                      .reduce((acc, obj) => acc + obj.pd, 0)
                      .toLocaleString("en-IN")}
                  </td>
                  <td className="rightalign">
                    {tdata
                      .reduce((acc, obj) => acc + obj.diff, 0)
                      .toLocaleString("en-IN")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <>
          <CircularProgress />
        </>
      )}
    </div>
  );
};

export default MyProfile;
